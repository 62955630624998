/**
 * @tableofcontents
 *
 * 1. Pre Loader
 */
/* @section 1. Pre Loader */
.cwpf_preLoader_wrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 99999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  padding: 0 50px;
}

.cwpf_preLoader_box {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.cwpf_preLoader_box div {
  position: absolute;
  top: 33px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  @apply bg-blue-100;
  -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
          animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.cwpf_preLoader_box div:nth-child(1) {
  left: 8px;
  -webkit-animation: cwpf_preLoader_box1 0.6s infinite;
          animation: cwpf_preLoader_box1 0.6s infinite;
}

.cwpf_preLoader_box div:nth-child(2) {
  left: 8px;
  -webkit-animation: cwpf_preLoader_box2 0.6s infinite;
          animation: cwpf_preLoader_box2 0.6s infinite;
}

.cwpf_preLoader_box div:nth-child(3) {
  left: 32px;
  -webkit-animation: cwpf_preLoader_box2 0.6s infinite;
          animation: cwpf_preLoader_box2 0.6s infinite;
}

.cwpf_preLoader_box div:nth-child(4) {
  left: 56px;
  -webkit-animation: cwpf_preLoader_box3 0.6s infinite;
          animation: cwpf_preLoader_box3 0.6s infinite;
}

@-webkit-keyframes cwpf_preLoader_box1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes cwpf_preLoader_box1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@-webkit-keyframes cwpf_preLoader_box3 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}

@keyframes cwpf_preLoader_box3 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}

@-webkit-keyframes cwpf_preLoader_box2 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(24px, 0);
            transform: translate(24px, 0);
  }
}

@keyframes cwpf_preLoader_box2 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(24px, 0);
            transform: translate(24px, 0);
  }
}
