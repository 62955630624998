/**
 * @tableofcontents
 *
 * 1. Add Application Form
 */
/* @section 1. Add Application Form */
.cwpf_addApp_sec {
  background: url("../../../../../Assets/img/add-application/pop-bg.svg") no-repeat left top;
  background-size: contain;
}

.cwpf_addAPP_form_inputBox {
  @apply mb-40 relative;
}

.cwpf_addAPP_form_inputBox label {
  @apply text-14 text-dark;
}

.cwpf_addAPP_form_inputBox input:not([type='radio']),
.cwpf_addAPP_form_inputBox select {
  @apply border border-gray-40 w-full p-12 mt-10 text-14 rounded-4;
}

.cwpf_addAPP_form_inputBox select {
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none;
  position: relative;
}

.cwpf_addAPP_form_selectBox {
  position: relative;
}

.cwpf_addAPP_form_selectBox::before {
  content: '\e5cf';
  font-family: 'Material Icons';
  @apply text-30 text-gray-30 absolute bottom-[6px] right-10 z-10;
}

.cwpf_app_slct_mainBox .cwpf_app_slctBtn {
  @apply md:w-[49%] text-center border border-gray-40 rounded-4 px-30 py-36 relative cursor-pointer sm:mb-10;
  text-indent: -99999px;
}

.cwpf_app_slct_mainBox .cwpf_app_slctBtn::before {
  content: '';
  width: 100%;
  height: 100%;
  border-radius: 4px;
  border: 2px solid transparent;
  position: absolute;
  left: 0;
  top: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.cwpf_app_slct_mainBox .cwpf_app_slctBtn img {
  @apply inline-block;
}

.cwpf_app_slct_mainBox .cwpf_app_slctBtn input[type='radio'] {
  @apply absolute top-0 left-0 w-full h-full opacity-0 z-10;
}

.cwpf_error_box {
  @apply text-red-100 text-12 absolute -bottom-24 left-0;
}

.cwpf_app_slctBtn.woocommerce {
  background: url("../../../../../Assets/img/add-application/woocommerce-logo.png") no-repeat center;
}

.cwpf_app_slctBtn.wordpress {
  background: url("../../../../../Assets/img/add-application/wordpress-logo.png") no-repeat center;
}

.cwpf_app_slctBtn.LMS {
  background: url("../../../../../Assets/img/add-application/lms-logo.png") no-repeat center;
}

.cwpf_app_slctBtn_actv {
  border-color: transparent !important;
}

.cwpf_app_slctBtn_actv::before {
  border-color: #2f39bf !important;
}

.cwpf_addAPP_form_wrap > form > *:not(:last-child) {
  @apply mb-40;
}

/* Custom Select Box */
.cwpf_customSelect_box .css-1s2u09g-control,
.cwpf_customSelect_box .css-1pahdxg-control {
  @apply border border-gray-40 w-[100%] p-4 text-14 rounded-4;
}

.cwpf_customSelect_box .css-1okebmr-indicatorSeparator {
  display: none;
}

@media (max-width: 992px) {
  .cwpf_app_slct_mainBox .cwpf_app_slctBtn {
    background-size: 80%;
  }
}

@media (max-width: 766px) {
  .cwpf_app_slct_mainBox .cwpf_app_slctBtn {
    background-size: unset;
  }
  .cwpf_addAPP_form_inputBox {
    @apply mb-20;
  }
}
