@media (max-width: 992px) {
  .cwpf_workflowResp {
    @apply flex-col;
    align-items: flex-start !important;
    .cwpf_workflowColResp {
      @apply mt-20;
    }
  }
}

@media (max-width: 767px) {
  .cwpf_BackupResp {
    @apply flex-col;
    align-items: baseline !important;
    .cwpf_BackupColResp {
      @apply mt-20;
    }
  }
}
//backup = > settings
.cwpf_setting_select {
  .css-1s2u09g-control,
  .css-18ml36i-control:hover{
    @apply border-none
  }
  .css-18ml36i-control{
    @apply border-none
  }
  .css-uy83eo-control{
    @apply border-none text-blue-100 cursor-pointer;
    &:hover{
      @apply border-none     
    }    
  }
 

  .css-14el2xx-placeholder {
    @apply text-blue-100 text-20 font-medium;
  }

  .css-tj5bde-Svg {
    @apply text-blue-100;
  }

  .css-1pahdxg-control {
    @apply shadow-none pointer-events-none cursor-not-allowed;
  }

  .css-6j8wv5-Input {
    @apply pointer-events-none cursor-not-allowed;
  }
}
//backup => restorData => restore logs
.cwpf_restore_backuprestore {
  display: none;
}
.cwpf_restorelogs_box {
  @apply relative;

  &:hover {
    .cwpf_restore_backuprestore {
      display: block;
    }
  }
}

.cwpf_restorelog_mainBox {
  @apply relative;
  overflow: hidden;
  .cwpf_restorelog_inrBox {
    @apply relative pl-[25px] pb-[5px];

    .cwpf_restorelogDtl_point {
      @apply w-18 h-18 rounded-[50%] bg-light inline-block absolute left-[1px] top-[10px] p-[5px];

      &::before {
        content: '';
        @apply w-[60%] h-[57%] rounded-[50%] border-2 border-blue-100 border-solid bg-light absolute left-[0px] top-[4px];
      }
    }

    .cwpf_restorelogDtl_timeBox {
      @apply absolute left-[-65px] top-[-1px];
    }

    &::before {
      content: '';
      @apply w-[2px] h-[100%] bg-gray-20 inline-block absolute left-[5px] top-[20px];
    }
    &:last-child {
      &::before {
        border: none;
        z-index: -3;
      }
    }

    &:first-child {
      &::after {
        top: -40px;
        height: 40px;
      }
    }
  }
}
.cwpf_restorelogs_box_Resp {
  align-items: center;
}

@media (max-width: 992px) {
  .cwpf_logs_box_Resp {
    flex-direction: column;
    align-items: flex-start;
  }
}
@media (max-width: 767px) {
  .cwpf_logs_innerbox_Resp {
    flex-direction: column;
  }
}
