.cwpf_userApp_screenShot {
  @apply relative;
}
.cwpf_customSelect_overview {
  .css-1s2u09g-control,
  .css-1pahdxg-control {
    @apply border border-gray-40 p-0 text-14 rounded-4;
  }
  .css-14el2xx-placeholder {
    @apply text-gray-80 px-10;
  }
  .css-tlfecz-indicatorContainer {
    @apply text-gray-80;
  }
}


@media (max-width: 767px) {
  .cwpf_resourcesResp{
    flex-direction: column;
  
  }
}