.cwpf_logs_box {
  @apply relative;
  
}

.cwpf_log_mainBox {
  @apply relative ;
  overflow: hidden;

  .cwpf_log_inrBox {
    @apply relative pl-[25px] pb-[5px];

    .cwpf_logDtl_point {
      @apply w-18 h-18 rounded-[50%] bg-light inline-block absolute left-[4px] top-[15px] p-[5px];

      &::before {
        content: '';
        @apply w-[60%] h-[57%] rounded-[50%] border-2 border-blue-100 border-solid bg-light absolute left-[5px] top-[4px];
      }
    }

    .cwpf_logDtl_timeBox {
      @apply absolute left-[-65px] top-[-1px];
    }

    &::before,
    &:first-child::after {
      content: '';
      @apply w-[2px] h-[100%] bg-gray-20 inline-block absolute left-[13px] top-[20px];
    }

    &:first-child {

      &::after {
        top: -40px;
        height: 40px;
      }
    }
  }
}
