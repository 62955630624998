/**
 * @tableofcontents
 *
 * 1. Side Navigatio Panel
 */
/* @section 1. Side Navigatio Panel */
.cwpf_sideNav_wrap {
  @apply bg-white fixed top-0 right-0 left-0 bottom-0  h-[100%] w-[48px] overflow-hidden;
  -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  -webkit-transition: width 0.3s linear;
  transition: width 0.3s linear;
  z-index: 99;
}

.cwpf_sideNav_wrap ul li {
  @apply relative min-w-[210px] inline-block my-14 px-12;
}

.cwpf_sideNav_wrap ul li a {
  @apply flex items-center;
}

.cwpf_sideNav_wrap ul li a span {
  @apply mr-20 text-gray-40;
}

.cwpf_sideNav_wrap ul li a b {
  @apply text-14 text-gray-80 font-normal opacity-0;
}

.cwpf_sideNav_wrap ul li a:not(.cwpf_disable_link):hover b {
  @apply text-blue-100;
}

.cwpf_sideNav_wrap ul li a:not(.cwpf_disable_link):hover svg path {
  fill: #2f39bf;
}

.cwpf_sideNav_wrap ul li a.cwpf_active_link b {
  @apply text-blue-100;
}

.cwpf_sideNav_wrap ul li a.cwpf_active_link svg path {
  fill: #2f39bf;
}

.cwpf_sideNav_wrap:hover {
  @apply w-[210px] overflow-visible;
}

.cwpf_sideNav_wrap:hover ul li b {
  @apply opacity-100;
}

.cwpf_sideNav_wrap:hover .cwpf_sideNav_bottom li a b {
  opacity: 1;
  visibility: visible;
  -webkit-transition: opacity 0.1s linear 0.2s;
  transition: opacity 0.1s linear 0.2s;
}

.cwpf_sideNav_wrap:hover .cwpf_sideNav_bottom .cwpf_old_route {
  opacity: 1;
  visibility: visible;
}

.cwpf_sideNav_wrap .cwpf_sideNav_top {
  @apply mt-[100px] overflow-x-hidden overflow-y-auto;
}

.cwpf_sideNav_wrap .cwpf_sideNav_top li a svg {
  @apply mr-20;
}

.cwpf_sideNav_wrap .cwpf_sideNav_bottom {
  /*.cwpf_old_route {
        opacity: 0;
        visibility: hidden;
      }*/
}

.cwpf_sideNav_wrap .cwpf_sideNav_bottom li a img {
  @apply mr-[5px];
}

.cwpf_sideNav_wrap .cwpf_sideNav_bottom li a b {
  @apply text-dark font-medium uppercase;
  opacity: 0;
  visibility: hidden;
}

.cwpf_sideNav_wrap .cwpf_sideNav_bottom li a b span {
  @apply text-[10px] text-gray-80 block;
}
