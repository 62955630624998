/**
 * @tableofcontents
 *
 * 1. Confirm Plan Component
 */
/* @section 1. Confirm Plan Component */
.cwpf_cnfrmPlan_sec {
  background: url("../../../../../../Assets/img/modal/modal-bg.png") no-repeat;
  @apply bg-contain;
}

.cwpf_cnfrmPlan_specBox li {
  @apply text-gray-80 text-16 pb-14;
}

.cwpf_cnfrmPlan_specBox li b {
  @apply float-right;
  font-weight: 400;
}

.cwpf_cnfrmPlan_noteBox {
  @apply pt-16 pl-20 mt-8 border-t border-t-gray-30 mb-10 text-[12px];
}

.cwpf_cnfrmPlan_noteBox li {
  @apply text-gray-80 text-12 pb-10 relative;
}

.cwpf_cnfrmPlan_noteBox li::before {
  content: '';
  @apply w-[6px] h-[6px] bg-dark absolute rounded-[100%]  -left-16 top-[5px];
}

/* .cwpf_cnfrmPlan_Price h4 b
  {
    @apply 
  
  } */
