/**
 * @tableofcontents
 *
 * 1. embed required font and set default attributes
 */

/* @section 1. embed required font and set default attributes */

/* Regular */

@font-face {
  font-family: 'poppins';
  font-style: normal;
  font-weight: 400;
  src: url('./poppins/poppins-regular-webfont.woff') format('woff'),
    url('./poppins/poppins-regular-webfont.woff2') format('woff2'),
    url('./poppins/poppins-regular.ttf') format('truetype');
  font-display: swap;
}

/* Medium */

@font-face {
  font-family: 'poppins';
  font-style: normal;
  font-weight: 500;
  src: url('./poppins/poppins-medium-webfont.woff') format('woff'),
    url('./poppins/poppins-medium-webfont.woff2') format('woff2'),
    url('./poppins/poppins-medium.ttf') format('truetype');
  font-display: swap;
}

/* Semi-Bold */

@font-face {
  font-family: 'poppins';
  font-style: normal;
  font-weight: 600;
  src: url('./poppins/poppins-semibold-webfont.woff') format('woff'),
    url('./poppins/poppins-semibold-webfont.woff2') format('woff2'),
    url('./poppins/poppins-semibold.ttf') format('truetype');
  font-display: swap;
}

/* Bold */

@font-face {
  font-family: 'poppins';
  font-style: normal;
  font-weight: 700;
  src: url('./poppins/poppins-bold-webfont.woff') format('woff'),
    url('./poppins/poppins-bold-webfont.woff2') format('woff2'),
    url('./poppins/poppins-bold.ttf') format('truetype');
  font-display: swap;
}
