.toggle-bg::after {
  content: '';
  @apply absolute top-[2px] left-[2px] bg-white border border-gray-10 rounded-full h-20 w-20 transition shadow-sm;
}
input:checked + .toggle-bg::after {
  transform: translateX(100%);
  @apply border-white;
}
input:checked + .toggle-bg {
  @apply bg-blue-100 border-blue-100;
}
