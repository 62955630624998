/**
 * @tableofcontents
 *
 * 1. Modal
 * 2. Max Limit Modal CSS
 */
/* @section 1. Modal */
.overlay {
  @apply flex items-center justify-center fixed top-0 left-0 right-0 bottom-0  bg-[rgba(0,0,0,0.8)] z-[9999] py-[25px] overflow-y-scroll;
  /* animation: fadeOut_anm 0.3s; */
}
.closeModal {
  @apply absolute top-0 left-0 right-0 bottom-0;
}
.cwpf_modal_wrap {
  @apply m-auto rounded-6 relative transition duration-[5s] ease-in-out bg-white z-[9999];
}
.cwpf_modal_wrap .close {
  @apply font-black text-gray-60 top-[13px] right-[16px] text-[40px] no-underline absolute  cursor-pointer  z-[10];
}
.cwpf_modal_wrap .close > path {
  @apply hover:fill-dark;
}
/*
.cwpf_modal_wrap {
  transition: -webkit-transform 0.5s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -25%);
  transform: translate(0, -25%);
}
.cwpf_modal_active .cwpf_modal_wrap {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}
@keyframes fadeIn_anm {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut_anm {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
} */
/* Modify Plan Modal */
.cwpf_slctPlan_sldrbox_error .cwpf_slctPlan_sldrSteps {
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#f02a10),
    to(#f5351c)
  );
  background-image: linear-gradient(#f02a10, #f5351c);
}
/* All Modals Width */
.cwpf_planAdded_modal,
.cwpf_appDelete_modal,
.cwpf_appLimit_modal,
.cwpf_bandWidth_modal,
.cwpf_unsubPlan_modal {
  @apply w-[540px];
}
.cwpf_domainOwnership_modal {
  @apply w-[40%];
}
.cwpf_domaincloudfaremodal {
  @apply w-[40%];
}
.cwpf_addApp_modal {
  @apply w-[56%];
}
.cwpf_modifyPlan_modal {
  @apply w-[1000px];
}
/* @section 2. Max Limit Modal CSS */
.cwpf_cnfrmPlan_mainsec {
  background: url('../../../../Assets/img/modal/modal-bg.png') no-repeat left
    top;
  @apply bg-contain;
}
.cwpf_cnfrmPlan_mainBox {
  @apply p-60 pb-30;
}
@media (max-width: 1199px) {
  .cwpf_planAdded_modal,
  .cwpf_appDelete_modal,
  .cwpf_appLimit_modal,
  .cwpf_bandWidth_modal,
  .cwpf_unsubPlan_modal {
    width: 50%;
  }
  .cwpf_modifyPlan_modal {
    width: 90%;
  }
}
@media (max-width: 1199px) {
  .cwpf_modifyPlan_modal {
    @apply w-[70%];
  }
}
@media (max-width: 992px) {
  .cwpf_planAdded_modal,
  .cwpf_appDelete_modal,
  .cwpf_appLimit_modal,
  .cwpf_bandWidth_modal,
  .cwpf_unsubPlan_modal {
    width: 70%;
  }
  .cwpf_addApp_modal {
    width: 90%;
  }
  .cwpf_modifyPlan_modal .cwpf_modifyPlan_sec {
    flex-flow: column;
  }
}
@media (max-width: 767px) {
}
@media (max-width: 600px) {
  .cwpf_planAdded_modal,
  .cwpf_appDelete_modal,
  .cwpf_appLimit_modal,
  .cwpf_bandWidth_modal,
  .cwpf_unsubPlan_modal {
    width: 90%;
  }
}
