/**
 * @tableofcontents
 *
 * 1. ManangeAccess Form
 */
/* @section 1. ManageAccess Form */
.cwpf_manageAccess_tabs .tabs {
  /* -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column; */
}

.cwpf_manageAccess_tabs .tabs .tab {
  margin-bottom: 20px;
}

.cwpf_manageAccess_tabs .tabs .tab:last-child {
  margin: 0 !important;
}

.cwpf_manageAccess_tabs .tabs .tab:hover {
  color: blue;
}

.cwpf_manageAccess_tabs .tabs .tab.tab-active {
  border-color: blue;
}

.cwpf_manageAccess_tabs .tabs a:hover {
  color: blue;
}

.cwpf_manageAccess_tabs .tab-bordered {
  border-left-width: 3px;
  border-bottom: 0px;
}

.cwpf_manageAccess_form_inputBox {
  @apply w-[294px];
  position: relative;
}

.cwpf_manageAccess_form_inputBox label {
  @apply text-16 text-gray-80;
}

.cwpf_manageAccess_form_inputBox_password {
  @apply w-[294px];
  position: relative;
}

.cwpf_manageAccess_form_inputBox_password label {
  @apply text-16 text-gray-80;
}

.cwpf_manageaccess_edituser_modal {
  @apply m-30;
}

.cwpf_manageaccess_adduser_modal {
  @apply m-30;
}

.manage_access_removeSShUser_modal {
  @apply m-24;
}

.manageaccess_Delete_sshUser_modal {
  @apply m-28;
}

.cw_confirmuser_btn {
  @apply px-24 py-12;
}

.cw_cancel_btn {
  @apply text-blue-100 px-24 py-12 flex items-center justify-center;
}

.manageaccess_launchdatabase_btn {
  @apply text-white bg-blue-100 rounded-4 px-32 py-8 flex items-center justify-center;
}

.cw_Deleteuser_btn {
  @apply text-white bg-red-100 px-24 py-8 rounded-4 flex items-center justify-center;
}

.cwpf_domain_rmv_btn {
  @apply py-12 px-20 border;
}

.cwpf_domainManagement_addbtn {
  @apply w-[177px];
}

.cwpf_add_sshusr_form > *:first-child {
  @apply mb-[45px];
}

/* FMP Global Table */
.cwpf_glb_tblBox tbody tr:not(:last-child) {
  @apply border-b border-gray-10;
}

.cwpf_glb_tblBox td {
  border: 0;
}

/* FMP Global Table */
@media (min-width: 1347px) {
  .cwpf_domainManagement_form {
    @apply flex-col;
  }
}

/*border-l border-l-gray-40*/
.cwpf_rmvdmain_pad {
  @apply px-24 py-10;
}

.cwpf_btnDisabled {
  background-color: #a5adba !important;
}

.cwpf_btnDisabled:hover {
  background-color: #a5adba !important;
}

.cwpf_domain_btn {
  @apply text-white bg-blue-100 rounded-4 px-2 py-8 flex items-center justify-center;
}

.cwpf_domainManagement_inputbox {
  @apply w-[400px];
}

.tab.tab-active {
  border-color: blue;
}

@media (max-width: 595px) {
  .cw_para {
    @apply w-10 ml-[50px];
  }
}

@media (max-width: 771px) {
  .cw_domain_inputbox {
    @apply w-auto;
  }
}

@media (max-width: 1772px) {
  .cwpf_help {
    @apply ml-80 justify-end;
  }
}

@media (max-width: 992px) {
  .cwpf_SshWrapResp {
    @apply flex-col mt-10;
  }
  .cwpf_SshWrapResp .cwpf_logsCtaWrapResp {
    @apply flex-col items-stretch;
  }
  .cwpf_SshWrapResp .cwpf_logsCtaWrapResp .cwpf_CtaResp {
    @apply mt-10;
  }
  .cwpf_domainResp {
    @apply flex-col;
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important;
  }
}

@media (max-width: 767px) {
  .cwpf_manageAccess_responsive {
    @apply flex-col;
  }
  .cwpf_SSHdestailsResp {
    @apply flex-col;
  }
  .cwpf_sshResp {
    @apply hidden;
  }
  .cwpf_ssh_imgResp {
    @apply translate-x-[-20%] translate-y-[-25%];
  }
  .cwpf_caching_table {
    @apply overflow-x-auto overflow-y-hidden;
  }
  table thead tr th {
    @apply relative;
  }
}

table tbody tr .cwpf_manageAccess_passToggleTd .cwpf_manageAccess_passwordToggle {
  display: none;
}

table tbody tr .cwpf_manageAccess_passToggleTd:hover .cwpf_manageAccess_passwordToggle {
  display: block;
}

table tbody tr .cwpf_manageAccess_userName {
  @apply sticky left-0;
}

.cwpf_domaincomplete_svg > path {
  @apply fill-blue-100;
}

.cwpf_domaincomplete_svg > g > path {
  @apply fill-blue-100;
}

.cwpf_domaincomplete_svg > g > rect {
  @apply fill-blue-100;
}
