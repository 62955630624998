.cwpf_userApp_screenShot {
  @apply relative;
}

.cwpf_customSelect_overview .css-1s2u09g-control,
.cwpf_customSelect_overview .css-1pahdxg-control {
  @apply border border-gray-40 p-0 text-14 rounded-4;
}

.cwpf_customSelect_overview .css-14el2xx-placeholder {
  @apply text-gray-80 px-10;
}

.cwpf_customSelect_overview .css-tlfecz-indicatorContainer {
  @apply text-gray-80;
}

@media (max-width: 767px) {
  .cwpf_resourcesResp {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
