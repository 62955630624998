.cwpf_btn_primary,
.cwpf_btn_primary[type='button'],
.cwpf_btn_primary[type='reset'],
.cwpf_btn_primary[type='submit'] {
  @apply text-white bg-blue-100 hover:bg-blue-75 active:bg-gray-80;
  @apply rounded-4 flex items-center justify-center;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.cwpf_btn_primary > span,
.cwpf_btn_primary[type='button'] > span,
.cwpf_btn_primary[type='reset'] > span,
.cwpf_btn_primary[type='submit'] > span {
  @apply text-white;
}

.cwpf_btn_primary > b > svg > path,
.cwpf_btn_primary[type='button'] > b > svg > path,
.cwpf_btn_primary[type='reset'] > b > svg > path,
.cwpf_btn_primary[type='submit'] > b > svg > path {
  @apply fill-white;
}

.cwpf_btn_primary > b,
.cwpf_btn_primary[type='button'] > b,
.cwpf_btn_primary[type='reset'] > b,
.cwpf_btn_primary[type='submit'] > b {
  @apply text-white;
}

.cwpf_btn_primary:hover > span,
.cwpf_btn_primary[type='button']:hover > span,
.cwpf_btn_primary[type='reset']:hover > span,
.cwpf_btn_primary[type='submit']:hover > span {
  @apply text-white;
}

.cwpf_btn_primary:hover > b > svg > path,
.cwpf_btn_primary[type='button']:hover > b > svg > path,
.cwpf_btn_primary[type='reset']:hover > b > svg > path,
.cwpf_btn_primary[type='submit']:hover > b > svg > path {
  @apply fill-white;
}

.cwpf_btn_primary:hover > b,
.cwpf_btn_primary[type='button']:hover > b,
.cwpf_btn_primary[type='reset']:hover > b,
.cwpf_btn_primary[type='submit']:hover > b {
  @apply text-white;
}

.cwpf_btn_primary:active > span,
.cwpf_btn_primary[type='button']:active > span,
.cwpf_btn_primary[type='reset']:active > span,
.cwpf_btn_primary[type='submit']:active > span {
  @apply text-white;
}

.cwpf_btn_primary:active > svg > path,
.cwpf_btn_primary[type='button']:active > svg > path,
.cwpf_btn_primary[type='reset']:active > svg > path,
.cwpf_btn_primary[type='submit']:active > svg > path {
  @apply fill-white;
}

.cwpf_btn_primary:active > b,
.cwpf_btn_primary[type='button']:active > b,
.cwpf_btn_primary[type='reset']:active > b,
.cwpf_btn_primary[type='submit']:active > b {
  @apply text-white;
}

.cwpf_btn_primary_disable,
.cwpf_btn_primary_disable[type='button'],
.cwpf_btn_primary_disable[type='reset'],
.cwpf_btn_primary_disable[type='submit'] {
  @apply rounded-4 flex items-center justify-center;
  @apply text-white bg-blue-25;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.cwpf_btn_primary_disable > span,
.cwpf_btn_primary_disable[type='button'] > span,
.cwpf_btn_primary_disable[type='reset'] > span,
.cwpf_btn_primary_disable[type='submit'] > span {
  @apply text-white;
}

.cwpf_btn_primary_disable > b > svg > path,
.cwpf_btn_primary_disable[type='button'] > b > svg > path,
.cwpf_btn_primary_disable[type='reset'] > b > svg > path,
.cwpf_btn_primary_disable[type='submit'] > b > svg > path {
  @apply fill-white;
}

.cwpf_btn_primary_disable > b,
.cwpf_btn_primary_disable[type='button'] > b,
.cwpf_btn_primary_disable[type='reset'] > b,
.cwpf_btn_primary_disable[type='submit'] > b {
  @apply text-white;
}

.cwpf_btn_primary_disable:hover > span,
.cwpf_btn_primary_disable[type='button']:hover > span,
.cwpf_btn_primary_disable[type='reset']:hover > span,
.cwpf_btn_primary_disable[type='submit']:hover > span {
  @apply text-white;
}

.cwpf_btn_primary_disable:hover > b > svg > path,
.cwpf_btn_primary_disable[type='button']:hover > b > svg > path,
.cwpf_btn_primary_disable[type='reset']:hover > b > svg > path,
.cwpf_btn_primary_disable[type='submit']:hover > b > svg > path {
  @apply fill-white;
}

.cwpf_btn_primary_disable:hover > b,
.cwpf_btn_primary_disable[type='button']:hover > b,
.cwpf_btn_primary_disable[type='reset']:hover > b,
.cwpf_btn_primary_disable[type='submit']:hover > b {
  @apply text-white;
}

.cwpf_btn_primary_disable:active > span,
.cwpf_btn_primary_disable[type='button']:active > span,
.cwpf_btn_primary_disable[type='reset']:active > span,
.cwpf_btn_primary_disable[type='submit']:active > span {
  @apply text-white;
}

.cwpf_btn_primary_disable:active > svg > path,
.cwpf_btn_primary_disable[type='button']:active > svg > path,
.cwpf_btn_primary_disable[type='reset']:active > svg > path,
.cwpf_btn_primary_disable[type='submit']:active > svg > path {
  @apply fill-white;
}

.cwpf_btn_primary_disable:active > b,
.cwpf_btn_primary_disable[type='button']:active > b,
.cwpf_btn_primary_disable[type='reset']:active > b,
.cwpf_btn_primary_disable[type='submit']:active > b {
  @apply text-white;
}

.cwpf_btn_secondary,
.cwpf_btn_secondary[type='button'],
.cwpf_btn_secondary[type='reset'],
.cwpf_btn_secondary[type='submit'] {
  @apply text-white bg-green-100 hover:bg-green-75 active:bg-gray-80;
  @apply rounded-4 flex items-center justify-center;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.cwpf_btn_secondary > span,
.cwpf_btn_secondary[type='button'] > span,
.cwpf_btn_secondary[type='reset'] > span,
.cwpf_btn_secondary[type='submit'] > span {
  @apply text-white;
}

.cwpf_btn_secondary > b > svg > path,
.cwpf_btn_secondary[type='button'] > b > svg > path,
.cwpf_btn_secondary[type='reset'] > b > svg > path,
.cwpf_btn_secondary[type='submit'] > b > svg > path {
  @apply fill-white;
}

.cwpf_btn_secondary > b,
.cwpf_btn_secondary[type='button'] > b,
.cwpf_btn_secondary[type='reset'] > b,
.cwpf_btn_secondary[type='submit'] > b {
  @apply text-white;
}

.cwpf_btn_secondary:hover > span,
.cwpf_btn_secondary[type='button']:hover > span,
.cwpf_btn_secondary[type='reset']:hover > span,
.cwpf_btn_secondary[type='submit']:hover > span {
  @apply text-white;
}

.cwpf_btn_secondary:hover > b > svg > path,
.cwpf_btn_secondary[type='button']:hover > b > svg > path,
.cwpf_btn_secondary[type='reset']:hover > b > svg > path,
.cwpf_btn_secondary[type='submit']:hover > b > svg > path {
  @apply fill-white;
}

.cwpf_btn_secondary:hover > b,
.cwpf_btn_secondary[type='button']:hover > b,
.cwpf_btn_secondary[type='reset']:hover > b,
.cwpf_btn_secondary[type='submit']:hover > b {
  @apply text-white;
}

.cwpf_btn_secondary:active > span,
.cwpf_btn_secondary[type='button']:active > span,
.cwpf_btn_secondary[type='reset']:active > span,
.cwpf_btn_secondary[type='submit']:active > span {
  @apply text-white;
}

.cwpf_btn_secondary:active > svg > path,
.cwpf_btn_secondary[type='button']:active > svg > path,
.cwpf_btn_secondary[type='reset']:active > svg > path,
.cwpf_btn_secondary[type='submit']:active > svg > path {
  @apply fill-white;
}

.cwpf_btn_secondary:active > b,
.cwpf_btn_secondary[type='button']:active > b,
.cwpf_btn_secondary[type='reset']:active > b,
.cwpf_btn_secondary[type='submit']:active > b {
  @apply text-white;
}

.cwpf_btn_secondary_disable,
.cwpf_btn_secondary_disable[type='button'],
.cwpf_btn_secondary_disable[type='reset'],
.cwpf_btn_secondary_disable[type='submit'] {
  @apply rounded-4 flex items-center justify-center;
  @apply text-gray-30 bg-gray-60;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.cwpf_btn_secondary_disable > span,
.cwpf_btn_secondary_disable[type='button'] > span,
.cwpf_btn_secondary_disable[type='reset'] > span,
.cwpf_btn_secondary_disable[type='submit'] > span {
  @apply text-gray-30;
}

.cwpf_btn_secondary_disable > b > svg > path,
.cwpf_btn_secondary_disable[type='button'] > b > svg > path,
.cwpf_btn_secondary_disable[type='reset'] > b > svg > path,
.cwpf_btn_secondary_disable[type='submit'] > b > svg > path {
  @apply fill-gray-30;
}

.cwpf_btn_secondary_disable > b,
.cwpf_btn_secondary_disable[type='button'] > b,
.cwpf_btn_secondary_disable[type='reset'] > b,
.cwpf_btn_secondary_disable[type='submit'] > b {
  @apply text-gray-30;
}

.cwpf_btn_secondary_disable:hover > span,
.cwpf_btn_secondary_disable[type='button']:hover > span,
.cwpf_btn_secondary_disable[type='reset']:hover > span,
.cwpf_btn_secondary_disable[type='submit']:hover > span {
  @apply text-gray-30;
}

.cwpf_btn_secondary_disable:hover > b > svg > path,
.cwpf_btn_secondary_disable[type='button']:hover > b > svg > path,
.cwpf_btn_secondary_disable[type='reset']:hover > b > svg > path,
.cwpf_btn_secondary_disable[type='submit']:hover > b > svg > path {
  @apply fill-gray-30;
}

.cwpf_btn_secondary_disable:hover > b,
.cwpf_btn_secondary_disable[type='button']:hover > b,
.cwpf_btn_secondary_disable[type='reset']:hover > b,
.cwpf_btn_secondary_disable[type='submit']:hover > b {
  @apply text-gray-30;
}

.cwpf_btn_secondary_disable:active > span,
.cwpf_btn_secondary_disable[type='button']:active > span,
.cwpf_btn_secondary_disable[type='reset']:active > span,
.cwpf_btn_secondary_disable[type='submit']:active > span {
  @apply text-gray-30;
}

.cwpf_btn_secondary_disable:active > svg > path,
.cwpf_btn_secondary_disable[type='button']:active > svg > path,
.cwpf_btn_secondary_disable[type='reset']:active > svg > path,
.cwpf_btn_secondary_disable[type='submit']:active > svg > path {
  @apply fill-gray-30;
}

.cwpf_btn_secondary_disable:active > b,
.cwpf_btn_secondary_disable[type='button']:active > b,
.cwpf_btn_secondary_disable[type='reset']:active > b,
.cwpf_btn_secondary_disable[type='submit']:active > b {
  @apply text-gray-30;
}

.cwpf_btn_danger,
.cwpf_btn_danger[type='button'],
.cwpf_btn_danger[type='reset'],
.cwpf_btn_danger[type='submit'] {
  @apply text-white bg-red-100 hover:bg-red-75 active:bg-red-50;
  @apply rounded-4 flex items-center justify-center;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.cwpf_btn_danger > span,
.cwpf_btn_danger[type='button'] > span,
.cwpf_btn_danger[type='reset'] > span,
.cwpf_btn_danger[type='submit'] > span {
  @apply text-white;
}

.cwpf_btn_danger > b > svg > path,
.cwpf_btn_danger[type='button'] > b > svg > path,
.cwpf_btn_danger[type='reset'] > b > svg > path,
.cwpf_btn_danger[type='submit'] > b > svg > path {
  @apply fill-white;
}

.cwpf_btn_danger > b,
.cwpf_btn_danger[type='button'] > b,
.cwpf_btn_danger[type='reset'] > b,
.cwpf_btn_danger[type='submit'] > b {
  @apply text-white;
}

.cwpf_btn_danger:hover > span,
.cwpf_btn_danger[type='button']:hover > span,
.cwpf_btn_danger[type='reset']:hover > span,
.cwpf_btn_danger[type='submit']:hover > span {
  @apply text-white;
}

.cwpf_btn_danger:hover > b > svg > path,
.cwpf_btn_danger[type='button']:hover > b > svg > path,
.cwpf_btn_danger[type='reset']:hover > b > svg > path,
.cwpf_btn_danger[type='submit']:hover > b > svg > path {
  @apply fill-white;
}

.cwpf_btn_danger:hover > b,
.cwpf_btn_danger[type='button']:hover > b,
.cwpf_btn_danger[type='reset']:hover > b,
.cwpf_btn_danger[type='submit']:hover > b {
  @apply text-white;
}

.cwpf_btn_danger:active > span,
.cwpf_btn_danger[type='button']:active > span,
.cwpf_btn_danger[type='reset']:active > span,
.cwpf_btn_danger[type='submit']:active > span {
  @apply text-white;
}

.cwpf_btn_danger:active > svg > path,
.cwpf_btn_danger[type='button']:active > svg > path,
.cwpf_btn_danger[type='reset']:active > svg > path,
.cwpf_btn_danger[type='submit']:active > svg > path {
  @apply fill-white;
}

.cwpf_btn_danger:active > b,
.cwpf_btn_danger[type='button']:active > b,
.cwpf_btn_danger[type='reset']:active > b,
.cwpf_btn_danger[type='submit']:active > b {
  @apply text-white;
}

.cwpf_btn_danger_disable,
.cwpf_btn_danger_disable[type='button'],
.cwpf_btn_danger_disable[type='reset'],
.cwpf_btn_danger_disable[type='submit'] {
  @apply rounded-4 flex items-center justify-center;
  @apply text-gray-30 bg-red-25;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.cwpf_btn_danger_disable > span,
.cwpf_btn_danger_disable[type='button'] > span,
.cwpf_btn_danger_disable[type='reset'] > span,
.cwpf_btn_danger_disable[type='submit'] > span {
  @apply text-gray-30;
}

.cwpf_btn_danger_disable > b > svg > path,
.cwpf_btn_danger_disable[type='button'] > b > svg > path,
.cwpf_btn_danger_disable[type='reset'] > b > svg > path,
.cwpf_btn_danger_disable[type='submit'] > b > svg > path {
  @apply fill-gray-30;
}

.cwpf_btn_danger_disable > b,
.cwpf_btn_danger_disable[type='button'] > b,
.cwpf_btn_danger_disable[type='reset'] > b,
.cwpf_btn_danger_disable[type='submit'] > b {
  @apply text-gray-30;
}

.cwpf_btn_danger_disable:hover > span,
.cwpf_btn_danger_disable[type='button']:hover > span,
.cwpf_btn_danger_disable[type='reset']:hover > span,
.cwpf_btn_danger_disable[type='submit']:hover > span {
  @apply text-gray-30;
}

.cwpf_btn_danger_disable:hover > b > svg > path,
.cwpf_btn_danger_disable[type='button']:hover > b > svg > path,
.cwpf_btn_danger_disable[type='reset']:hover > b > svg > path,
.cwpf_btn_danger_disable[type='submit']:hover > b > svg > path {
  @apply fill-gray-30;
}

.cwpf_btn_danger_disable:hover > b,
.cwpf_btn_danger_disable[type='button']:hover > b,
.cwpf_btn_danger_disable[type='reset']:hover > b,
.cwpf_btn_danger_disable[type='submit']:hover > b {
  @apply text-gray-30;
}

.cwpf_btn_danger_disable:active > span,
.cwpf_btn_danger_disable[type='button']:active > span,
.cwpf_btn_danger_disable[type='reset']:active > span,
.cwpf_btn_danger_disable[type='submit']:active > span {
  @apply text-gray-30;
}

.cwpf_btn_danger_disable:active > svg > path,
.cwpf_btn_danger_disable[type='button']:active > svg > path,
.cwpf_btn_danger_disable[type='reset']:active > svg > path,
.cwpf_btn_danger_disable[type='submit']:active > svg > path {
  @apply fill-gray-30;
}

.cwpf_btn_danger_disable:active > b,
.cwpf_btn_danger_disable[type='button']:active > b,
.cwpf_btn_danger_disable[type='reset']:active > b,
.cwpf_btn_danger_disable[type='submit']:active > b {
  @apply text-gray-30;
}

.cwpf_btn_neutral,
.cwpf_btn_neutral[type='button'],
.cwpf_btn_neutral[type='reset'],
.cwpf_btn_neutral[type='submit'] {
  @apply text-white bg-gray-100 hover:bg-gray-80 active:bg-gray-60;
  @apply rounded-4 flex items-center justify-center;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.cwpf_btn_neutral > span,
.cwpf_btn_neutral[type='button'] > span,
.cwpf_btn_neutral[type='reset'] > span,
.cwpf_btn_neutral[type='submit'] > span {
  @apply text-white;
}

.cwpf_btn_neutral > b > svg > path,
.cwpf_btn_neutral[type='button'] > b > svg > path,
.cwpf_btn_neutral[type='reset'] > b > svg > path,
.cwpf_btn_neutral[type='submit'] > b > svg > path {
  @apply fill-white;
}

.cwpf_btn_neutral > b,
.cwpf_btn_neutral[type='button'] > b,
.cwpf_btn_neutral[type='reset'] > b,
.cwpf_btn_neutral[type='submit'] > b {
  @apply text-white;
}

.cwpf_btn_neutral:hover > span,
.cwpf_btn_neutral[type='button']:hover > span,
.cwpf_btn_neutral[type='reset']:hover > span,
.cwpf_btn_neutral[type='submit']:hover > span {
  @apply text-white;
}

.cwpf_btn_neutral:hover > b > svg > path,
.cwpf_btn_neutral[type='button']:hover > b > svg > path,
.cwpf_btn_neutral[type='reset']:hover > b > svg > path,
.cwpf_btn_neutral[type='submit']:hover > b > svg > path {
  @apply fill-white;
}

.cwpf_btn_neutral:hover > b,
.cwpf_btn_neutral[type='button']:hover > b,
.cwpf_btn_neutral[type='reset']:hover > b,
.cwpf_btn_neutral[type='submit']:hover > b {
  @apply text-white;
}

.cwpf_btn_neutral:active > span,
.cwpf_btn_neutral[type='button']:active > span,
.cwpf_btn_neutral[type='reset']:active > span,
.cwpf_btn_neutral[type='submit']:active > span {
  @apply text-white;
}

.cwpf_btn_neutral:active > svg > path,
.cwpf_btn_neutral[type='button']:active > svg > path,
.cwpf_btn_neutral[type='reset']:active > svg > path,
.cwpf_btn_neutral[type='submit']:active > svg > path {
  @apply fill-white;
}

.cwpf_btn_neutral:active > b,
.cwpf_btn_neutral[type='button']:active > b,
.cwpf_btn_neutral[type='reset']:active > b,
.cwpf_btn_neutral[type='submit']:active > b {
  @apply text-white;
}

.cwpf_btn_neutral_disable,
.cwpf_btn_neutral_disable[type='button'],
.cwpf_btn_neutral_disable[type='reset'],
.cwpf_btn_neutral_disable[type='submit'] {
  @apply rounded-4 flex items-center justify-center;
  @apply text-gray-30 bg-gray-3;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.cwpf_btn_neutral_disable > span,
.cwpf_btn_neutral_disable[type='button'] > span,
.cwpf_btn_neutral_disable[type='reset'] > span,
.cwpf_btn_neutral_disable[type='submit'] > span {
  @apply text-gray-30;
}

.cwpf_btn_neutral_disable > b > svg > path,
.cwpf_btn_neutral_disable[type='button'] > b > svg > path,
.cwpf_btn_neutral_disable[type='reset'] > b > svg > path,
.cwpf_btn_neutral_disable[type='submit'] > b > svg > path {
  @apply fill-gray-30;
}

.cwpf_btn_neutral_disable > b,
.cwpf_btn_neutral_disable[type='button'] > b,
.cwpf_btn_neutral_disable[type='reset'] > b,
.cwpf_btn_neutral_disable[type='submit'] > b {
  @apply text-gray-30;
}

.cwpf_btn_neutral_disable:hover > span,
.cwpf_btn_neutral_disable[type='button']:hover > span,
.cwpf_btn_neutral_disable[type='reset']:hover > span,
.cwpf_btn_neutral_disable[type='submit']:hover > span {
  @apply text-gray-30;
}

.cwpf_btn_neutral_disable:hover > b > svg > path,
.cwpf_btn_neutral_disable[type='button']:hover > b > svg > path,
.cwpf_btn_neutral_disable[type='reset']:hover > b > svg > path,
.cwpf_btn_neutral_disable[type='submit']:hover > b > svg > path {
  @apply fill-gray-30;
}

.cwpf_btn_neutral_disable:hover > b,
.cwpf_btn_neutral_disable[type='button']:hover > b,
.cwpf_btn_neutral_disable[type='reset']:hover > b,
.cwpf_btn_neutral_disable[type='submit']:hover > b {
  @apply text-gray-30;
}

.cwpf_btn_neutral_disable:active > span,
.cwpf_btn_neutral_disable[type='button']:active > span,
.cwpf_btn_neutral_disable[type='reset']:active > span,
.cwpf_btn_neutral_disable[type='submit']:active > span {
  @apply text-gray-30;
}

.cwpf_btn_neutral_disable:active > svg > path,
.cwpf_btn_neutral_disable[type='button']:active > svg > path,
.cwpf_btn_neutral_disable[type='reset']:active > svg > path,
.cwpf_btn_neutral_disable[type='submit']:active > svg > path {
  @apply fill-gray-30;
}

.cwpf_btn_neutral_disable:active > b,
.cwpf_btn_neutral_disable[type='button']:active > b,
.cwpf_btn_neutral_disable[type='reset']:active > b,
.cwpf_btn_neutral_disable[type='submit']:active > b {
  @apply text-gray-30;
}

.cwpf_btn_primary_outline,
.cwpf_btn_primary_outline[type='button'],
.cwpf_btn_primary_outline[type='reset'],
.cwpf_btn_primary_outline[type='submit'] {
  @apply rounded-4 flex items-center justify-center;
  @apply text-blue-100 border border-blue-100 hover:text-white hover:bg-blue-75 active:text-white active:bg-gray-80;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.cwpf_btn_primary_outline > span,
.cwpf_btn_primary_outline[type='button'] > span,
.cwpf_btn_primary_outline[type='reset'] > span,
.cwpf_btn_primary_outline[type='submit'] > span {
  @apply text-blue-100;
}

.cwpf_btn_primary_outline > b > svg > path,
.cwpf_btn_primary_outline[type='button'] > b > svg > path,
.cwpf_btn_primary_outline[type='reset'] > b > svg > path,
.cwpf_btn_primary_outline[type='submit'] > b > svg > path {
  @apply fill-blue-100;
}

.cwpf_btn_primary_outline > b,
.cwpf_btn_primary_outline[type='button'] > b,
.cwpf_btn_primary_outline[type='reset'] > b,
.cwpf_btn_primary_outline[type='submit'] > b {
  @apply text-blue-100;
}

.cwpf_btn_primary_outline:hover > span,
.cwpf_btn_primary_outline[type='button']:hover > span,
.cwpf_btn_primary_outline[type='reset']:hover > span,
.cwpf_btn_primary_outline[type='submit']:hover > span {
  @apply text-white;
}

.cwpf_btn_primary_outline:hover > b > svg > path,
.cwpf_btn_primary_outline[type='button']:hover > b > svg > path,
.cwpf_btn_primary_outline[type='reset']:hover > b > svg > path,
.cwpf_btn_primary_outline[type='submit']:hover > b > svg > path {
  @apply fill-white;
}

.cwpf_btn_primary_outline:hover > b,
.cwpf_btn_primary_outline[type='button']:hover > b,
.cwpf_btn_primary_outline[type='reset']:hover > b,
.cwpf_btn_primary_outline[type='submit']:hover > b {
  @apply text-white;
}

.cwpf_btn_primary_outline:active > span,
.cwpf_btn_primary_outline[type='button']:active > span,
.cwpf_btn_primary_outline[type='reset']:active > span,
.cwpf_btn_primary_outline[type='submit']:active > span {
  @apply text-white;
}

.cwpf_btn_primary_outline:active > svg > path,
.cwpf_btn_primary_outline[type='button']:active > svg > path,
.cwpf_btn_primary_outline[type='reset']:active > svg > path,
.cwpf_btn_primary_outline[type='submit']:active > svg > path {
  @apply fill-white;
}

.cwpf_btn_primary_outline:active > b,
.cwpf_btn_primary_outline[type='button']:active > b,
.cwpf_btn_primary_outline[type='reset']:active > b,
.cwpf_btn_primary_outline[type='submit']:active > b {
  @apply text-white;
}

.cwpf_btn_primary_outline_disable,
.cwpf_btn_primary_outline_disable[type='button'],
.cwpf_btn_primary_outline_disable[type='reset'],
.cwpf_btn_primary_outline_disable[type='submit'] {
  @apply rounded-4 flex items-center justify-center;
  @apply text-blue-25 border border-blue-25;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.cwpf_btn_primary_outline_disable > span,
.cwpf_btn_primary_outline_disable[type='button'] > span,
.cwpf_btn_primary_outline_disable[type='reset'] > span,
.cwpf_btn_primary_outline_disable[type='submit'] > span {
  @apply text-blue-25;
}

.cwpf_btn_primary_outline_disable > b > svg > path,
.cwpf_btn_primary_outline_disable[type='button'] > b > svg > path,
.cwpf_btn_primary_outline_disable[type='reset'] > b > svg > path,
.cwpf_btn_primary_outline_disable[type='submit'] > b > svg > path {
  @apply fill-blue-25;
}

.cwpf_btn_primary_outline_disable > b,
.cwpf_btn_primary_outline_disable[type='button'] > b,
.cwpf_btn_primary_outline_disable[type='reset'] > b,
.cwpf_btn_primary_outline_disable[type='submit'] > b {
  @apply text-blue-25;
}

.cwpf_btn_primary_outline_disable:hover > span,
.cwpf_btn_primary_outline_disable[type='button']:hover > span,
.cwpf_btn_primary_outline_disable[type='reset']:hover > span,
.cwpf_btn_primary_outline_disable[type='submit']:hover > span {
  @apply text-blue-25;
}

.cwpf_btn_primary_outline_disable:hover > b > svg > path,
.cwpf_btn_primary_outline_disable[type='button']:hover > b > svg > path,
.cwpf_btn_primary_outline_disable[type='reset']:hover > b > svg > path,
.cwpf_btn_primary_outline_disable[type='submit']:hover > b > svg > path {
  @apply fill-blue-25;
}

.cwpf_btn_primary_outline_disable:hover > b,
.cwpf_btn_primary_outline_disable[type='button']:hover > b,
.cwpf_btn_primary_outline_disable[type='reset']:hover > b,
.cwpf_btn_primary_outline_disable[type='submit']:hover > b {
  @apply text-blue-25;
}

.cwpf_btn_primary_outline_disable:active > span,
.cwpf_btn_primary_outline_disable[type='button']:active > span,
.cwpf_btn_primary_outline_disable[type='reset']:active > span,
.cwpf_btn_primary_outline_disable[type='submit']:active > span {
  @apply text-blue-25;
}

.cwpf_btn_primary_outline_disable:active > svg > path,
.cwpf_btn_primary_outline_disable[type='button']:active > svg > path,
.cwpf_btn_primary_outline_disable[type='reset']:active > svg > path,
.cwpf_btn_primary_outline_disable[type='submit']:active > svg > path {
  @apply fill-blue-25;
}

.cwpf_btn_primary_outline_disable:active > b,
.cwpf_btn_primary_outline_disable[type='button']:active > b,
.cwpf_btn_primary_outline_disable[type='reset']:active > b,
.cwpf_btn_primary_outline_disable[type='submit']:active > b {
  @apply text-blue-25;
}

.cwpf_btn_secondary_outline,
.cwpf_btn_secondary_outline[type='button'],
.cwpf_btn_secondary_outline[type='reset'],
.cwpf_btn_secondary_outline[type='submit'] {
  @apply rounded-4 flex items-center justify-center;
  @apply text-green-100 border border-green-100 hover:text-white hover:bg-green-75 active:text-white active:bg-gray-80;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.cwpf_btn_secondary_outline > span,
.cwpf_btn_secondary_outline[type='button'] > span,
.cwpf_btn_secondary_outline[type='reset'] > span,
.cwpf_btn_secondary_outline[type='submit'] > span {
  @apply text-green-100;
}

.cwpf_btn_secondary_outline > b > svg > path,
.cwpf_btn_secondary_outline[type='button'] > b > svg > path,
.cwpf_btn_secondary_outline[type='reset'] > b > svg > path,
.cwpf_btn_secondary_outline[type='submit'] > b > svg > path {
  @apply fill-green-100;
}

.cwpf_btn_secondary_outline > b,
.cwpf_btn_secondary_outline[type='button'] > b,
.cwpf_btn_secondary_outline[type='reset'] > b,
.cwpf_btn_secondary_outline[type='submit'] > b {
  @apply text-green-100;
}

.cwpf_btn_secondary_outline:hover > span,
.cwpf_btn_secondary_outline[type='button']:hover > span,
.cwpf_btn_secondary_outline[type='reset']:hover > span,
.cwpf_btn_secondary_outline[type='submit']:hover > span {
  @apply text-white;
}

.cwpf_btn_secondary_outline:hover > b > svg > path,
.cwpf_btn_secondary_outline[type='button']:hover > b > svg > path,
.cwpf_btn_secondary_outline[type='reset']:hover > b > svg > path,
.cwpf_btn_secondary_outline[type='submit']:hover > b > svg > path {
  @apply fill-white;
}

.cwpf_btn_secondary_outline:hover > b,
.cwpf_btn_secondary_outline[type='button']:hover > b,
.cwpf_btn_secondary_outline[type='reset']:hover > b,
.cwpf_btn_secondary_outline[type='submit']:hover > b {
  @apply text-white;
}

.cwpf_btn_secondary_outline:active > span,
.cwpf_btn_secondary_outline[type='button']:active > span,
.cwpf_btn_secondary_outline[type='reset']:active > span,
.cwpf_btn_secondary_outline[type='submit']:active > span {
  @apply text-white;
}

.cwpf_btn_secondary_outline:active > svg > path,
.cwpf_btn_secondary_outline[type='button']:active > svg > path,
.cwpf_btn_secondary_outline[type='reset']:active > svg > path,
.cwpf_btn_secondary_outline[type='submit']:active > svg > path {
  @apply fill-white;
}

.cwpf_btn_secondary_outline:active > b,
.cwpf_btn_secondary_outline[type='button']:active > b,
.cwpf_btn_secondary_outline[type='reset']:active > b,
.cwpf_btn_secondary_outline[type='submit']:active > b {
  @apply text-white;
}

.cwpf_btn_secondary_outline_disable,
.cwpf_btn_secondary_outline_disable[type='button'],
.cwpf_btn_secondary_outline_disable[type='reset'],
.cwpf_btn_secondary_outline_disable[type='submit'] {
  @apply rounded-4 flex items-center justify-center;
  @apply text-blue-25 border border-gray-20;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.cwpf_btn_secondary_outline_disable > span,
.cwpf_btn_secondary_outline_disable[type='button'] > span,
.cwpf_btn_secondary_outline_disable[type='reset'] > span,
.cwpf_btn_secondary_outline_disable[type='submit'] > span {
  @apply text-blue-25;
}

.cwpf_btn_secondary_outline_disable > b > svg > path,
.cwpf_btn_secondary_outline_disable[type='button'] > b > svg > path,
.cwpf_btn_secondary_outline_disable[type='reset'] > b > svg > path,
.cwpf_btn_secondary_outline_disable[type='submit'] > b > svg > path {
  @apply fill-blue-25;
}

.cwpf_btn_secondary_outline_disable > b,
.cwpf_btn_secondary_outline_disable[type='button'] > b,
.cwpf_btn_secondary_outline_disable[type='reset'] > b,
.cwpf_btn_secondary_outline_disable[type='submit'] > b {
  @apply text-blue-25;
}

.cwpf_btn_secondary_outline_disable:hover > span,
.cwpf_btn_secondary_outline_disable[type='button']:hover > span,
.cwpf_btn_secondary_outline_disable[type='reset']:hover > span,
.cwpf_btn_secondary_outline_disable[type='submit']:hover > span {
  @apply text-blue-25;
}

.cwpf_btn_secondary_outline_disable:hover > b > svg > path,
.cwpf_btn_secondary_outline_disable[type='button']:hover > b > svg > path,
.cwpf_btn_secondary_outline_disable[type='reset']:hover > b > svg > path,
.cwpf_btn_secondary_outline_disable[type='submit']:hover > b > svg > path {
  @apply fill-blue-25;
}

.cwpf_btn_secondary_outline_disable:hover > b,
.cwpf_btn_secondary_outline_disable[type='button']:hover > b,
.cwpf_btn_secondary_outline_disable[type='reset']:hover > b,
.cwpf_btn_secondary_outline_disable[type='submit']:hover > b {
  @apply text-blue-25;
}

.cwpf_btn_secondary_outline_disable:active > span,
.cwpf_btn_secondary_outline_disable[type='button']:active > span,
.cwpf_btn_secondary_outline_disable[type='reset']:active > span,
.cwpf_btn_secondary_outline_disable[type='submit']:active > span {
  @apply text-blue-25;
}

.cwpf_btn_secondary_outline_disable:active > svg > path,
.cwpf_btn_secondary_outline_disable[type='button']:active > svg > path,
.cwpf_btn_secondary_outline_disable[type='reset']:active > svg > path,
.cwpf_btn_secondary_outline_disable[type='submit']:active > svg > path {
  @apply fill-blue-25;
}

.cwpf_btn_secondary_outline_disable:active > b,
.cwpf_btn_secondary_outline_disable[type='button']:active > b,
.cwpf_btn_secondary_outline_disable[type='reset']:active > b,
.cwpf_btn_secondary_outline_disable[type='submit']:active > b {
  @apply text-blue-25;
}

.cwpf_btn_neutral_outline,
.cwpf_btn_neutral_outline[type='button'],
.cwpf_btn_neutral_outline[type='reset'],
.cwpf_btn_neutral_outline[type='submit'] {
  @apply rounded-4 flex items-center justify-center;
  @apply text-dark border border-dark hover:text-white hover:bg-gray-80 active:text-white active:bg-gray-60;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.cwpf_btn_neutral_outline > span,
.cwpf_btn_neutral_outline[type='button'] > span,
.cwpf_btn_neutral_outline[type='reset'] > span,
.cwpf_btn_neutral_outline[type='submit'] > span {
  @apply text-dark;
}

.cwpf_btn_neutral_outline > b > svg > path,
.cwpf_btn_neutral_outline[type='button'] > b > svg > path,
.cwpf_btn_neutral_outline[type='reset'] > b > svg > path,
.cwpf_btn_neutral_outline[type='submit'] > b > svg > path {
  @apply fill-dark;
}

.cwpf_btn_neutral_outline > b,
.cwpf_btn_neutral_outline[type='button'] > b,
.cwpf_btn_neutral_outline[type='reset'] > b,
.cwpf_btn_neutral_outline[type='submit'] > b {
  @apply text-dark;
}

.cwpf_btn_neutral_outline:hover > span,
.cwpf_btn_neutral_outline[type='button']:hover > span,
.cwpf_btn_neutral_outline[type='reset']:hover > span,
.cwpf_btn_neutral_outline[type='submit']:hover > span {
  @apply text-white;
}

.cwpf_btn_neutral_outline:hover > b > svg > path,
.cwpf_btn_neutral_outline[type='button']:hover > b > svg > path,
.cwpf_btn_neutral_outline[type='reset']:hover > b > svg > path,
.cwpf_btn_neutral_outline[type='submit']:hover > b > svg > path {
  @apply fill-white;
}

.cwpf_btn_neutral_outline:hover > b,
.cwpf_btn_neutral_outline[type='button']:hover > b,
.cwpf_btn_neutral_outline[type='reset']:hover > b,
.cwpf_btn_neutral_outline[type='submit']:hover > b {
  @apply text-white;
}

.cwpf_btn_neutral_outline:active > span,
.cwpf_btn_neutral_outline[type='button']:active > span,
.cwpf_btn_neutral_outline[type='reset']:active > span,
.cwpf_btn_neutral_outline[type='submit']:active > span {
  @apply text-white;
}

.cwpf_btn_neutral_outline:active > svg > path,
.cwpf_btn_neutral_outline[type='button']:active > svg > path,
.cwpf_btn_neutral_outline[type='reset']:active > svg > path,
.cwpf_btn_neutral_outline[type='submit']:active > svg > path {
  @apply fill-white;
}

.cwpf_btn_neutral_outline:active > b,
.cwpf_btn_neutral_outline[type='button']:active > b,
.cwpf_btn_neutral_outline[type='reset']:active > b,
.cwpf_btn_neutral_outline[type='submit']:active > b {
  @apply text-white;
}

.cwpf_btn_neutral_outline_disable,
.cwpf_btn_neutral_outline_disable[type='button'],
.cwpf_btn_neutral_outline_disable[type='reset'],
.cwpf_btn_neutral_outline_disable[type='submit'] {
  @apply rounded-4 flex items-center justify-center;
  @apply text-gray-20 border border-gray-30;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.cwpf_btn_neutral_outline_disable > span,
.cwpf_btn_neutral_outline_disable[type='button'] > span,
.cwpf_btn_neutral_outline_disable[type='reset'] > span,
.cwpf_btn_neutral_outline_disable[type='submit'] > span {
  @apply text-gray-20;
}

.cwpf_btn_neutral_outline_disable > b > svg > path,
.cwpf_btn_neutral_outline_disable[type='button'] > b > svg > path,
.cwpf_btn_neutral_outline_disable[type='reset'] > b > svg > path,
.cwpf_btn_neutral_outline_disable[type='submit'] > b > svg > path {
  @apply fill-gray-20;
}

.cwpf_btn_neutral_outline_disable > b,
.cwpf_btn_neutral_outline_disable[type='button'] > b,
.cwpf_btn_neutral_outline_disable[type='reset'] > b,
.cwpf_btn_neutral_outline_disable[type='submit'] > b {
  @apply text-gray-20;
}

.cwpf_btn_neutral_outline_disable:hover > span,
.cwpf_btn_neutral_outline_disable[type='button']:hover > span,
.cwpf_btn_neutral_outline_disable[type='reset']:hover > span,
.cwpf_btn_neutral_outline_disable[type='submit']:hover > span {
  @apply text-gray-20;
}

.cwpf_btn_neutral_outline_disable:hover > b > svg > path,
.cwpf_btn_neutral_outline_disable[type='button']:hover > b > svg > path,
.cwpf_btn_neutral_outline_disable[type='reset']:hover > b > svg > path,
.cwpf_btn_neutral_outline_disable[type='submit']:hover > b > svg > path {
  @apply fill-gray-20;
}

.cwpf_btn_neutral_outline_disable:hover > b,
.cwpf_btn_neutral_outline_disable[type='button']:hover > b,
.cwpf_btn_neutral_outline_disable[type='reset']:hover > b,
.cwpf_btn_neutral_outline_disable[type='submit']:hover > b {
  @apply text-gray-20;
}

.cwpf_btn_neutral_outline_disable:active > span,
.cwpf_btn_neutral_outline_disable[type='button']:active > span,
.cwpf_btn_neutral_outline_disable[type='reset']:active > span,
.cwpf_btn_neutral_outline_disable[type='submit']:active > span {
  @apply text-gray-20;
}

.cwpf_btn_neutral_outline_disable:active > svg > path,
.cwpf_btn_neutral_outline_disable[type='button']:active > svg > path,
.cwpf_btn_neutral_outline_disable[type='reset']:active > svg > path,
.cwpf_btn_neutral_outline_disable[type='submit']:active > svg > path {
  @apply fill-gray-20;
}

.cwpf_btn_neutral_outline_disable:active > b,
.cwpf_btn_neutral_outline_disable[type='button']:active > b,
.cwpf_btn_neutral_outline_disable[type='reset']:active > b,
.cwpf_btn_neutral_outline_disable[type='submit']:active > b {
  @apply text-gray-20;
}

.cwpf_btn_danger_outline,
.cwpf_btn_danger_outline[type='button'],
.cwpf_btn_danger_outline[type='reset'],
.cwpf_btn_danger_outline[type='submit'] {
  @apply rounded-4 flex items-center justify-center;
  @apply text-red-100 border border-red-100 hover:text-white hover:bg-red-75 active:text-white active:bg-red-50;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.cwpf_btn_danger_outline > span,
.cwpf_btn_danger_outline[type='button'] > span,
.cwpf_btn_danger_outline[type='reset'] > span,
.cwpf_btn_danger_outline[type='submit'] > span {
  @apply text-red-100;
}

.cwpf_btn_danger_outline > b > svg > path,
.cwpf_btn_danger_outline[type='button'] > b > svg > path,
.cwpf_btn_danger_outline[type='reset'] > b > svg > path,
.cwpf_btn_danger_outline[type='submit'] > b > svg > path {
  @apply fill-red-100;
}

.cwpf_btn_danger_outline > b,
.cwpf_btn_danger_outline[type='button'] > b,
.cwpf_btn_danger_outline[type='reset'] > b,
.cwpf_btn_danger_outline[type='submit'] > b {
  @apply text-red-100;
}

.cwpf_btn_danger_outline:hover > span,
.cwpf_btn_danger_outline[type='button']:hover > span,
.cwpf_btn_danger_outline[type='reset']:hover > span,
.cwpf_btn_danger_outline[type='submit']:hover > span {
  @apply text-white;
}

.cwpf_btn_danger_outline:hover > b > svg > path,
.cwpf_btn_danger_outline[type='button']:hover > b > svg > path,
.cwpf_btn_danger_outline[type='reset']:hover > b > svg > path,
.cwpf_btn_danger_outline[type='submit']:hover > b > svg > path {
  @apply fill-white;
}

.cwpf_btn_danger_outline:hover > b,
.cwpf_btn_danger_outline[type='button']:hover > b,
.cwpf_btn_danger_outline[type='reset']:hover > b,
.cwpf_btn_danger_outline[type='submit']:hover > b {
  @apply text-white;
}

.cwpf_btn_danger_outline:active > span,
.cwpf_btn_danger_outline[type='button']:active > span,
.cwpf_btn_danger_outline[type='reset']:active > span,
.cwpf_btn_danger_outline[type='submit']:active > span {
  @apply text-white;
}

.cwpf_btn_danger_outline:active > svg > path,
.cwpf_btn_danger_outline[type='button']:active > svg > path,
.cwpf_btn_danger_outline[type='reset']:active > svg > path,
.cwpf_btn_danger_outline[type='submit']:active > svg > path {
  @apply fill-white;
}

.cwpf_btn_danger_outline:active > b,
.cwpf_btn_danger_outline[type='button']:active > b,
.cwpf_btn_danger_outline[type='reset']:active > b,
.cwpf_btn_danger_outline[type='submit']:active > b {
  @apply text-white;
}

.cwpf_btn_danger_outline_disable,
.cwpf_btn_danger_outline_disable[type='button'],
.cwpf_btn_danger_outline_disable[type='reset'],
.cwpf_btn_danger_outline_disable[type='submit'] {
  @apply rounded-4 flex items-center justify-center;
  @apply text-red-25 border border-gray-20;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.cwpf_btn_danger_outline_disable > span,
.cwpf_btn_danger_outline_disable[type='button'] > span,
.cwpf_btn_danger_outline_disable[type='reset'] > span,
.cwpf_btn_danger_outline_disable[type='submit'] > span {
  @apply text-red-25;
}

.cwpf_btn_danger_outline_disable > b > svg > path,
.cwpf_btn_danger_outline_disable[type='button'] > b > svg > path,
.cwpf_btn_danger_outline_disable[type='reset'] > b > svg > path,
.cwpf_btn_danger_outline_disable[type='submit'] > b > svg > path {
  @apply fill-red-25;
}

.cwpf_btn_danger_outline_disable > b,
.cwpf_btn_danger_outline_disable[type='button'] > b,
.cwpf_btn_danger_outline_disable[type='reset'] > b,
.cwpf_btn_danger_outline_disable[type='submit'] > b {
  @apply text-red-25;
}

.cwpf_btn_danger_outline_disable:hover > span,
.cwpf_btn_danger_outline_disable[type='button']:hover > span,
.cwpf_btn_danger_outline_disable[type='reset']:hover > span,
.cwpf_btn_danger_outline_disable[type='submit']:hover > span {
  @apply text-red-25;
}

.cwpf_btn_danger_outline_disable:hover > b > svg > path,
.cwpf_btn_danger_outline_disable[type='button']:hover > b > svg > path,
.cwpf_btn_danger_outline_disable[type='reset']:hover > b > svg > path,
.cwpf_btn_danger_outline_disable[type='submit']:hover > b > svg > path {
  @apply fill-red-25;
}

.cwpf_btn_danger_outline_disable:hover > b,
.cwpf_btn_danger_outline_disable[type='button']:hover > b,
.cwpf_btn_danger_outline_disable[type='reset']:hover > b,
.cwpf_btn_danger_outline_disable[type='submit']:hover > b {
  @apply text-red-25;
}

.cwpf_btn_danger_outline_disable:active > span,
.cwpf_btn_danger_outline_disable[type='button']:active > span,
.cwpf_btn_danger_outline_disable[type='reset']:active > span,
.cwpf_btn_danger_outline_disable[type='submit']:active > span {
  @apply text-red-25;
}

.cwpf_btn_danger_outline_disable:active > svg > path,
.cwpf_btn_danger_outline_disable[type='button']:active > svg > path,
.cwpf_btn_danger_outline_disable[type='reset']:active > svg > path,
.cwpf_btn_danger_outline_disable[type='submit']:active > svg > path {
  @apply fill-red-25;
}

.cwpf_btn_danger_outline_disable:active > b,
.cwpf_btn_danger_outline_disable[type='button']:active > b,
.cwpf_btn_danger_outline_disable[type='reset']:active > b,
.cwpf_btn_danger_outline_disable[type='submit']:active > b {
  @apply text-red-25;
}

.cwpf_btn_primary_text,
.cwpf_btn_primary_text [type='button'],
.cwpf_btn_primary_text[type='reset'],
.cwpf_btn_primary_text[type='submit'] {
  @apply rounded-4 flex items-center justify-center;
  @apply text-blue-100 hover:text-blue-75 active:text-gray-80;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.cwpf_btn_primary_text > span,
.cwpf_btn_primary_text [type='button'] > span,
.cwpf_btn_primary_text[type='reset'] > span,
.cwpf_btn_primary_text[type='submit'] > span {
  @apply text-blue-100;
}

.cwpf_btn_primary_text > b > svg > path,
.cwpf_btn_primary_text [type='button'] > b > svg > path,
.cwpf_btn_primary_text[type='reset'] > b > svg > path,
.cwpf_btn_primary_text[type='submit'] > b > svg > path {
  @apply fill-blue-100;
}

.cwpf_btn_primary_text > b,
.cwpf_btn_primary_text [type='button'] > b,
.cwpf_btn_primary_text[type='reset'] > b,
.cwpf_btn_primary_text[type='submit'] > b {
  @apply text-blue-100;
}

.cwpf_btn_primary_text:hover > span,
.cwpf_btn_primary_text [type='button']:hover > span,
.cwpf_btn_primary_text[type='reset']:hover > span,
.cwpf_btn_primary_text[type='submit']:hover > span {
  @apply text-blue-75;
}

.cwpf_btn_primary_text:hover > b > svg > path,
.cwpf_btn_primary_text [type='button']:hover > b > svg > path,
.cwpf_btn_primary_text[type='reset']:hover > b > svg > path,
.cwpf_btn_primary_text[type='submit']:hover > b > svg > path {
  @apply fill-blue-75;
}

.cwpf_btn_primary_text:hover > b,
.cwpf_btn_primary_text [type='button']:hover > b,
.cwpf_btn_primary_text[type='reset']:hover > b,
.cwpf_btn_primary_text[type='submit']:hover > b {
  @apply text-blue-75;
}

.cwpf_btn_primary_text:active > span,
.cwpf_btn_primary_text [type='button']:active > span,
.cwpf_btn_primary_text[type='reset']:active > span,
.cwpf_btn_primary_text[type='submit']:active > span {
  @apply text-gray-80;
}

.cwpf_btn_primary_text:active > svg > path,
.cwpf_btn_primary_text [type='button']:active > svg > path,
.cwpf_btn_primary_text[type='reset']:active > svg > path,
.cwpf_btn_primary_text[type='submit']:active > svg > path {
  @apply fill-gray-80;
}

.cwpf_btn_primary_text:active > b,
.cwpf_btn_primary_text [type='button']:active > b,
.cwpf_btn_primary_text[type='reset']:active > b,
.cwpf_btn_primary_text[type='submit']:active > b {
  @apply text-gray-80;
}

.cwpf_btn_primary_text_disable,
.cwpf_btn_primary_text_disable [type='button'],
.cwpf_btn_primary_text_disable[type='reset'],
.cwpf_btn_primary_text_disable[type='submit'] {
  @apply rounded-4 flex items-center justify-center;
  @apply text-blue-25;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.cwpf_btn_primary_text_disable > span,
.cwpf_btn_primary_text_disable [type='button'] > span,
.cwpf_btn_primary_text_disable[type='reset'] > span,
.cwpf_btn_primary_text_disable[type='submit'] > span {
  @apply text-blue-25;
}

.cwpf_btn_primary_text_disable > b > svg > path,
.cwpf_btn_primary_text_disable [type='button'] > b > svg > path,
.cwpf_btn_primary_text_disable[type='reset'] > b > svg > path,
.cwpf_btn_primary_text_disable[type='submit'] > b > svg > path {
  @apply fill-blue-25;
}

.cwpf_btn_primary_text_disable > b,
.cwpf_btn_primary_text_disable [type='button'] > b,
.cwpf_btn_primary_text_disable[type='reset'] > b,
.cwpf_btn_primary_text_disable[type='submit'] > b {
  @apply text-blue-25;
}

.cwpf_btn_primary_text_disable:hover > span,
.cwpf_btn_primary_text_disable [type='button']:hover > span,
.cwpf_btn_primary_text_disable[type='reset']:hover > span,
.cwpf_btn_primary_text_disable[type='submit']:hover > span {
  @apply text-blue-25;
}

.cwpf_btn_primary_text_disable:hover > b > svg > path,
.cwpf_btn_primary_text_disable [type='button']:hover > b > svg > path,
.cwpf_btn_primary_text_disable[type='reset']:hover > b > svg > path,
.cwpf_btn_primary_text_disable[type='submit']:hover > b > svg > path {
  @apply fill-blue-25;
}

.cwpf_btn_primary_text_disable:hover > b,
.cwpf_btn_primary_text_disable [type='button']:hover > b,
.cwpf_btn_primary_text_disable[type='reset']:hover > b,
.cwpf_btn_primary_text_disable[type='submit']:hover > b {
  @apply text-blue-25;
}

.cwpf_btn_primary_text_disable:active > span,
.cwpf_btn_primary_text_disable [type='button']:active > span,
.cwpf_btn_primary_text_disable[type='reset']:active > span,
.cwpf_btn_primary_text_disable[type='submit']:active > span {
  @apply text-blue-25;
}

.cwpf_btn_primary_text_disable:active > svg > path,
.cwpf_btn_primary_text_disable [type='button']:active > svg > path,
.cwpf_btn_primary_text_disable[type='reset']:active > svg > path,
.cwpf_btn_primary_text_disable[type='submit']:active > svg > path {
  @apply fill-blue-25;
}

.cwpf_btn_primary_text_disable:active > b,
.cwpf_btn_primary_text_disable [type='button']:active > b,
.cwpf_btn_primary_text_disable[type='reset']:active > b,
.cwpf_btn_primary_text_disable[type='submit']:active > b {
  @apply text-blue-25;
}

.cwpf_btn_secondary_text,
.cwpf_btn_secondary_text [type='button'],
.cwpf_btn_secondary_text[type='reset'],
.cwpf_btn_secondary_text[type='submit'] {
  @apply rounded-4 flex items-center justify-center;
  @apply text-green-100 hover:text-green-75 active:text-gray-80;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.cwpf_btn_secondary_text > span,
.cwpf_btn_secondary_text [type='button'] > span,
.cwpf_btn_secondary_text[type='reset'] > span,
.cwpf_btn_secondary_text[type='submit'] > span {
  @apply text-green-100;
}

.cwpf_btn_secondary_text > b > svg > path,
.cwpf_btn_secondary_text [type='button'] > b > svg > path,
.cwpf_btn_secondary_text[type='reset'] > b > svg > path,
.cwpf_btn_secondary_text[type='submit'] > b > svg > path {
  @apply fill-green-100;
}

.cwpf_btn_secondary_text > b,
.cwpf_btn_secondary_text [type='button'] > b,
.cwpf_btn_secondary_text[type='reset'] > b,
.cwpf_btn_secondary_text[type='submit'] > b {
  @apply text-green-100;
}

.cwpf_btn_secondary_text:hover > span,
.cwpf_btn_secondary_text [type='button']:hover > span,
.cwpf_btn_secondary_text[type='reset']:hover > span,
.cwpf_btn_secondary_text[type='submit']:hover > span {
  @apply text-green-75;
}

.cwpf_btn_secondary_text:hover > b > svg > path,
.cwpf_btn_secondary_text [type='button']:hover > b > svg > path,
.cwpf_btn_secondary_text[type='reset']:hover > b > svg > path,
.cwpf_btn_secondary_text[type='submit']:hover > b > svg > path {
  @apply fill-green-75;
}

.cwpf_btn_secondary_text:hover > b,
.cwpf_btn_secondary_text [type='button']:hover > b,
.cwpf_btn_secondary_text[type='reset']:hover > b,
.cwpf_btn_secondary_text[type='submit']:hover > b {
  @apply text-green-75;
}

.cwpf_btn_secondary_text:active > span,
.cwpf_btn_secondary_text [type='button']:active > span,
.cwpf_btn_secondary_text[type='reset']:active > span,
.cwpf_btn_secondary_text[type='submit']:active > span {
  @apply text-gray-80;
}

.cwpf_btn_secondary_text:active > svg > path,
.cwpf_btn_secondary_text [type='button']:active > svg > path,
.cwpf_btn_secondary_text[type='reset']:active > svg > path,
.cwpf_btn_secondary_text[type='submit']:active > svg > path {
  @apply fill-gray-80;
}

.cwpf_btn_secondary_text:active > b,
.cwpf_btn_secondary_text [type='button']:active > b,
.cwpf_btn_secondary_text[type='reset']:active > b,
.cwpf_btn_secondary_text[type='submit']:active > b {
  @apply text-gray-80;
}

.cwpf_btn_secondary_text_disable,
.cwpf_btn_secondary_text_disable [type='button'],
.cwpf_btn_secondary_text_disable[type='reset'],
.cwpf_btn_secondary_text_disable[type='submit'] {
  @apply rounded-4 flex items-center justify-center;
  @apply text-green-25;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.cwpf_btn_secondary_text_disable > span,
.cwpf_btn_secondary_text_disable [type='button'] > span,
.cwpf_btn_secondary_text_disable[type='reset'] > span,
.cwpf_btn_secondary_text_disable[type='submit'] > span {
  @apply text-green-25;
}

.cwpf_btn_secondary_text_disable > b > svg > path,
.cwpf_btn_secondary_text_disable [type='button'] > b > svg > path,
.cwpf_btn_secondary_text_disable[type='reset'] > b > svg > path,
.cwpf_btn_secondary_text_disable[type='submit'] > b > svg > path {
  @apply fill-green-25;
}

.cwpf_btn_secondary_text_disable > b,
.cwpf_btn_secondary_text_disable [type='button'] > b,
.cwpf_btn_secondary_text_disable[type='reset'] > b,
.cwpf_btn_secondary_text_disable[type='submit'] > b {
  @apply text-green-25;
}

.cwpf_btn_secondary_text_disable:hover > span,
.cwpf_btn_secondary_text_disable [type='button']:hover > span,
.cwpf_btn_secondary_text_disable[type='reset']:hover > span,
.cwpf_btn_secondary_text_disable[type='submit']:hover > span {
  @apply text-green-25;
}

.cwpf_btn_secondary_text_disable:hover > b > svg > path,
.cwpf_btn_secondary_text_disable [type='button']:hover > b > svg > path,
.cwpf_btn_secondary_text_disable[type='reset']:hover > b > svg > path,
.cwpf_btn_secondary_text_disable[type='submit']:hover > b > svg > path {
  @apply fill-green-25;
}

.cwpf_btn_secondary_text_disable:hover > b,
.cwpf_btn_secondary_text_disable [type='button']:hover > b,
.cwpf_btn_secondary_text_disable[type='reset']:hover > b,
.cwpf_btn_secondary_text_disable[type='submit']:hover > b {
  @apply text-green-25;
}

.cwpf_btn_secondary_text_disable:active > span,
.cwpf_btn_secondary_text_disable [type='button']:active > span,
.cwpf_btn_secondary_text_disable[type='reset']:active > span,
.cwpf_btn_secondary_text_disable[type='submit']:active > span {
  @apply text-green-25;
}

.cwpf_btn_secondary_text_disable:active > svg > path,
.cwpf_btn_secondary_text_disable [type='button']:active > svg > path,
.cwpf_btn_secondary_text_disable[type='reset']:active > svg > path,
.cwpf_btn_secondary_text_disable[type='submit']:active > svg > path {
  @apply fill-green-25;
}

.cwpf_btn_secondary_text_disable:active > b,
.cwpf_btn_secondary_text_disable [type='button']:active > b,
.cwpf_btn_secondary_text_disable[type='reset']:active > b,
.cwpf_btn_secondary_text_disable[type='submit']:active > b {
  @apply text-green-25;
}

.cwpf_btn_neutral_text,
.cwpf_btn_neutral_text [type='button'],
.cwpf_btn_neutral_text[type='reset'],
.cwpf_btn_neutral_text[type='submit'] {
  @apply rounded-4 flex items-center justify-center;
  @apply text-dark hover:text-gray-80 active:text-gray-60;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.cwpf_btn_neutral_text > span,
.cwpf_btn_neutral_text [type='button'] > span,
.cwpf_btn_neutral_text[type='reset'] > span,
.cwpf_btn_neutral_text[type='submit'] > span {
  @apply text-dark;
}

.cwpf_btn_neutral_text > b > svg > path,
.cwpf_btn_neutral_text [type='button'] > b > svg > path,
.cwpf_btn_neutral_text[type='reset'] > b > svg > path,
.cwpf_btn_neutral_text[type='submit'] > b > svg > path {
  @apply fill-dark;
}

.cwpf_btn_neutral_text > b,
.cwpf_btn_neutral_text [type='button'] > b,
.cwpf_btn_neutral_text[type='reset'] > b,
.cwpf_btn_neutral_text[type='submit'] > b {
  @apply text-dark;
}

.cwpf_btn_neutral_text:hover > span,
.cwpf_btn_neutral_text [type='button']:hover > span,
.cwpf_btn_neutral_text[type='reset']:hover > span,
.cwpf_btn_neutral_text[type='submit']:hover > span {
  @apply text-gray-80;
}

.cwpf_btn_neutral_text:hover > b > svg > path,
.cwpf_btn_neutral_text [type='button']:hover > b > svg > path,
.cwpf_btn_neutral_text[type='reset']:hover > b > svg > path,
.cwpf_btn_neutral_text[type='submit']:hover > b > svg > path {
  @apply fill-gray-80;
}

.cwpf_btn_neutral_text:hover > b,
.cwpf_btn_neutral_text [type='button']:hover > b,
.cwpf_btn_neutral_text[type='reset']:hover > b,
.cwpf_btn_neutral_text[type='submit']:hover > b {
  @apply text-gray-80;
}

.cwpf_btn_neutral_text:active > span,
.cwpf_btn_neutral_text [type='button']:active > span,
.cwpf_btn_neutral_text[type='reset']:active > span,
.cwpf_btn_neutral_text[type='submit']:active > span {
  @apply text-gray-60;
}

.cwpf_btn_neutral_text:active > svg > path,
.cwpf_btn_neutral_text [type='button']:active > svg > path,
.cwpf_btn_neutral_text[type='reset']:active > svg > path,
.cwpf_btn_neutral_text[type='submit']:active > svg > path {
  @apply fill-gray-60;
}

.cwpf_btn_neutral_text:active > b,
.cwpf_btn_neutral_text [type='button']:active > b,
.cwpf_btn_neutral_text[type='reset']:active > b,
.cwpf_btn_neutral_text[type='submit']:active > b {
  @apply text-gray-60;
}

.cwpf_btn_neutral_text_disable,
.cwpf_btn_neutral_text_disable [type='button'],
.cwpf_btn_neutral_text_disable[type='reset'],
.cwpf_btn_neutral_text_disable[type='submit'] {
  @apply rounded-4 flex items-center justify-center;
  @apply text-gray-30;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.cwpf_btn_neutral_text_disable > span,
.cwpf_btn_neutral_text_disable [type='button'] > span,
.cwpf_btn_neutral_text_disable[type='reset'] > span,
.cwpf_btn_neutral_text_disable[type='submit'] > span {
  @apply text-gray-30;
}

.cwpf_btn_neutral_text_disable > b > svg > path,
.cwpf_btn_neutral_text_disable [type='button'] > b > svg > path,
.cwpf_btn_neutral_text_disable[type='reset'] > b > svg > path,
.cwpf_btn_neutral_text_disable[type='submit'] > b > svg > path {
  @apply fill-gray-30;
}

.cwpf_btn_neutral_text_disable > b,
.cwpf_btn_neutral_text_disable [type='button'] > b,
.cwpf_btn_neutral_text_disable[type='reset'] > b,
.cwpf_btn_neutral_text_disable[type='submit'] > b {
  @apply text-gray-30;
}

.cwpf_btn_neutral_text_disable:hover > span,
.cwpf_btn_neutral_text_disable [type='button']:hover > span,
.cwpf_btn_neutral_text_disable[type='reset']:hover > span,
.cwpf_btn_neutral_text_disable[type='submit']:hover > span {
  @apply text-gray-30;
}

.cwpf_btn_neutral_text_disable:hover > b > svg > path,
.cwpf_btn_neutral_text_disable [type='button']:hover > b > svg > path,
.cwpf_btn_neutral_text_disable[type='reset']:hover > b > svg > path,
.cwpf_btn_neutral_text_disable[type='submit']:hover > b > svg > path {
  @apply fill-gray-30;
}

.cwpf_btn_neutral_text_disable:hover > b,
.cwpf_btn_neutral_text_disable [type='button']:hover > b,
.cwpf_btn_neutral_text_disable[type='reset']:hover > b,
.cwpf_btn_neutral_text_disable[type='submit']:hover > b {
  @apply text-gray-30;
}

.cwpf_btn_neutral_text_disable:active > span,
.cwpf_btn_neutral_text_disable [type='button']:active > span,
.cwpf_btn_neutral_text_disable[type='reset']:active > span,
.cwpf_btn_neutral_text_disable[type='submit']:active > span {
  @apply text-gray-30;
}

.cwpf_btn_neutral_text_disable:active > svg > path,
.cwpf_btn_neutral_text_disable [type='button']:active > svg > path,
.cwpf_btn_neutral_text_disable[type='reset']:active > svg > path,
.cwpf_btn_neutral_text_disable[type='submit']:active > svg > path {
  @apply fill-gray-30;
}

.cwpf_btn_neutral_text_disable:active > b,
.cwpf_btn_neutral_text_disable [type='button']:active > b,
.cwpf_btn_neutral_text_disable[type='reset']:active > b,
.cwpf_btn_neutral_text_disable[type='submit']:active > b {
  @apply text-gray-30;
}

.cwpf_btn_danger_text,
.cwpf_btn_danger_text [type='button'],
.cwpf_btn_danger_text[type='reset'],
.cwpf_btn_danger_text[type='submit'] {
  @apply rounded-4 flex items-center justify-center;
  @apply text-red-100 hover:text-red-75 active:text-red-50;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.cwpf_btn_danger_text > span,
.cwpf_btn_danger_text [type='button'] > span,
.cwpf_btn_danger_text[type='reset'] > span,
.cwpf_btn_danger_text[type='submit'] > span {
  @apply text-red-100;
}

.cwpf_btn_danger_text > b > svg > path,
.cwpf_btn_danger_text [type='button'] > b > svg > path,
.cwpf_btn_danger_text[type='reset'] > b > svg > path,
.cwpf_btn_danger_text[type='submit'] > b > svg > path {
  @apply fill-red-100;
}

.cwpf_btn_danger_text > b,
.cwpf_btn_danger_text [type='button'] > b,
.cwpf_btn_danger_text[type='reset'] > b,
.cwpf_btn_danger_text[type='submit'] > b {
  @apply text-red-100;
}

.cwpf_btn_danger_text:hover > span,
.cwpf_btn_danger_text [type='button']:hover > span,
.cwpf_btn_danger_text[type='reset']:hover > span,
.cwpf_btn_danger_text[type='submit']:hover > span {
  @apply text-red-75;
}

.cwpf_btn_danger_text:hover > b > svg > path,
.cwpf_btn_danger_text [type='button']:hover > b > svg > path,
.cwpf_btn_danger_text[type='reset']:hover > b > svg > path,
.cwpf_btn_danger_text[type='submit']:hover > b > svg > path {
  @apply fill-red-75;
}

.cwpf_btn_danger_text:hover > b,
.cwpf_btn_danger_text [type='button']:hover > b,
.cwpf_btn_danger_text[type='reset']:hover > b,
.cwpf_btn_danger_text[type='submit']:hover > b {
  @apply text-red-75;
}

.cwpf_btn_danger_text:active > span,
.cwpf_btn_danger_text [type='button']:active > span,
.cwpf_btn_danger_text[type='reset']:active > span,
.cwpf_btn_danger_text[type='submit']:active > span {
  @apply text-red-50;
}

.cwpf_btn_danger_text:active > svg > path,
.cwpf_btn_danger_text [type='button']:active > svg > path,
.cwpf_btn_danger_text[type='reset']:active > svg > path,
.cwpf_btn_danger_text[type='submit']:active > svg > path {
  @apply fill-red-50;
}

.cwpf_btn_danger_text:active > b,
.cwpf_btn_danger_text [type='button']:active > b,
.cwpf_btn_danger_text[type='reset']:active > b,
.cwpf_btn_danger_text[type='submit']:active > b {
  @apply text-red-50;
}

.cwpf_btn_danger_text_disable,
.cwpf_btn_danger_text_disable [type='button'],
.cwpf_btn_danger_text_disable[type='reset'],
.cwpf_btn_danger_text_disable[type='submit'] {
  @apply rounded-4 flex items-center justify-center;
  @apply text-red-25;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.cwpf_btn_danger_text_disable > span,
.cwpf_btn_danger_text_disable [type='button'] > span,
.cwpf_btn_danger_text_disable[type='reset'] > span,
.cwpf_btn_danger_text_disable[type='submit'] > span {
  @apply text-red-25;
}

.cwpf_btn_danger_text_disable > b > svg > path,
.cwpf_btn_danger_text_disable [type='button'] > b > svg > path,
.cwpf_btn_danger_text_disable[type='reset'] > b > svg > path,
.cwpf_btn_danger_text_disable[type='submit'] > b > svg > path {
  @apply fill-red-25;
}

.cwpf_btn_danger_text_disable > b,
.cwpf_btn_danger_text_disable [type='button'] > b,
.cwpf_btn_danger_text_disable[type='reset'] > b,
.cwpf_btn_danger_text_disable[type='submit'] > b {
  @apply text-red-25;
}

.cwpf_btn_danger_text_disable:hover > span,
.cwpf_btn_danger_text_disable [type='button']:hover > span,
.cwpf_btn_danger_text_disable[type='reset']:hover > span,
.cwpf_btn_danger_text_disable[type='submit']:hover > span {
  @apply text-red-25;
}

.cwpf_btn_danger_text_disable:hover > b > svg > path,
.cwpf_btn_danger_text_disable [type='button']:hover > b > svg > path,
.cwpf_btn_danger_text_disable[type='reset']:hover > b > svg > path,
.cwpf_btn_danger_text_disable[type='submit']:hover > b > svg > path {
  @apply fill-red-25;
}

.cwpf_btn_danger_text_disable:hover > b,
.cwpf_btn_danger_text_disable [type='button']:hover > b,
.cwpf_btn_danger_text_disable[type='reset']:hover > b,
.cwpf_btn_danger_text_disable[type='submit']:hover > b {
  @apply text-red-25;
}

.cwpf_btn_danger_text_disable:active > span,
.cwpf_btn_danger_text_disable [type='button']:active > span,
.cwpf_btn_danger_text_disable[type='reset']:active > span,
.cwpf_btn_danger_text_disable[type='submit']:active > span {
  @apply text-red-25;
}

.cwpf_btn_danger_text_disable:active > svg > path,
.cwpf_btn_danger_text_disable [type='button']:active > svg > path,
.cwpf_btn_danger_text_disable[type='reset']:active > svg > path,
.cwpf_btn_danger_text_disable[type='submit']:active > svg > path {
  @apply fill-red-25;
}

.cwpf_btn_danger_text_disable:active > b,
.cwpf_btn_danger_text_disable [type='button']:active > b,
.cwpf_btn_danger_text_disable[type='reset']:active > b,
.cwpf_btn_danger_text_disable[type='submit']:active > b {
  @apply text-red-25;
}

.cwpf_glb_spinner_btn::after {
  content: '';
  margin-right: 0.5rem;
  height: 1rem;
  width: 1rem;
  border-radius: 9999px;
  border-width: 2px;
  -webkit-animation: spin 2s linear infinite;
          animation: spin 2s linear infinite;
  border-top-color: transparent;
  border-left-color: transparent;
  border-bottom-color: currentColor;
  border-right-color: currentColor;
}
