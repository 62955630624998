/**
 * @tableofcontents
 *
 * 1. imports
 * 2. header
 * 3. Find Plan Slider
 * 4. Other Features
 * 5. Customer Testimonial
 */

/* @section 1. imports */

@import url('./generic.css');

/* Dashboard */
/* left Panel */
.cwPlf_db_mainWrap {
  display: flex;

  .cwPlf_db_lftWrap {
    flex: 1;
  }

  .cwPlf_db_rhtWrap {
    flex: 10;
  }
}

.cw_ttl_box {
  @apply xl:text-heading lg:text-green-100 md:text-red-100 sm:text-blue-100 xs:text-orange-100;
}

/*
.cw_glb_btn
{
    @apply bg-blue-50 text-green-100 hover:bg-blue-100 hover:text-light p-4 max-w-sm mx-auto rounded-xl shadow-lg flex items-center space-x-4
}
*/

/* Right Panel */
