.cwpf_attackmitigated_table {
  @apply text-gray-80;
}

.cwpf_attackmitigated_table > thead {
  @apply border-b border-solid border-gray-20;
}

.cwpf_attackmitigated_table > thead > tr > th {
  @apply border-b border-solid border-gray-40;
  background-color: transparent;
  @apply normal-case;
}

.cwpf_attackmitigated_table > tbody {
  @apply border-t border-solid border-gray-20;
}

.cwpf_attackmitigated_table > tbody > tr > td {
  @apply border-b border-solid border-gray-10;
  background-color: transparent;
  @apply normal-case;
}

@media (max-width: 992px) {
  .cwpf_AttackMitiResp {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .cwpf_AttackMitiResp .cwpf_AttackMitiColResp {
    margin-bottom: 20px;
  }
}
