/* section1. dashboard buttons, tabs */
.cwpf_dashBoard_drawer {
    transform: translateX(110%);
  }
  .cwpf_dashBoard_drawer_actv {
    transform: translateX(0);
  }
  .cwpf_dashboard_border {
    @apply border-b border-gray-3 pb-12;
  }
  .cwpf_dashboard_img {
    @apply w-80 h-60 ml-10;
  }
  .cwpf_dashboard_btn {
    @apply mt-20 rounded btn-wide ml-40;
  }
  .cwpf_dashboard_appdropwdown_head {
    @apply ml-16 font-bold;
  }
  .cwpf_dashboard_appDrawerBox {
    @apply flex;
    li {
      @apply mr-12;
      span {
        @apply relative text-18 text-gray-60 inline-block align-middle;
      }
      b {
        @apply text-12 text-gray-80 font-medium inline-block align-middle;
      }
    }
  }
  .cwpf_dashBoard_statsTab {
    li {
      @apply text-16 text-gray-80 inline-block py-14 font-medium hover:text-blue-100 cursor-pointer border-b-[3px] border-transparent;
  
      &:nth-of-type(2) {
        margin: 0 30px;
      }
  
      &.cwpf_dashBoard_statsTab_actv {
        @apply text-blue-100 border-blue-100;
      }
    }
  }
  
  /* section2. dashboard cards */
  .cwpf_dashboard_performance_card{
    @apply px-20 py-20 flex flex-col place-content-between h-full
  }
  
  .cwpf_dashboard_accessibility_card{
    @apply px-20 py-18 flex flex-col gap-8 place-content-between h-full
  }
  
  .cwpf_dashboard_bestPracticses_card{
    @apply px-20 py-18 flex flex-col gap-4 place-content-between h-full
  }
  
  .cwpf_dashboard_seo_card{
    @apply px-20 py-18 flex flex-col place-content-between h-full
  }
  
  .cwpf_dashboard_appStats_borderCard {
    @apply border border-solid border-gray-10 rounded-4 basis-[48%] relative px-24 py-20;
  }
  
  
  /*section3. dashboard Responsive design */
  @media (max-width: 1200px) {
    .cwpf_dashboard_appStats_borderCard {
      @apply flex-wrap space-x-0 space-y-10 min-w-[25%];
    }
  }
  
  @media (max-width: 1386px) {
    .cwpf_dashboard_top {
      @apply flex-wrap-reverse;
    }
  }
  @media (max-width: 992px) {
    .cwpf_dashboard_chart {
      @apply flex-col items-center;
    }
    .cwpf_dashboardVitals {
      @apply flex-wrap;
      // justify-content: inherit !important;
      .cwpf_dashboardVitals_wrap {
        @apply max-w-[50%];
        flex: 0 0 50%;
      }
    }
  }
  @media (max-width: 767px) {
    .cwpf_dashboard_appStats_card {
      .cwpf_dashboard_appStats_borderCard {
        @apply min-w-[100%];
      }
    }
  }
  
  .select-wrapper {
    position: relative;
    display: flex;
    justify-content: flex-end;
    width: 90px;
    .select.select-info {
      background-image: none;
      appearance: none;
      width: 100%;
    }
    &::before {
      content: '\e5cf';
      font-family: 'Material Icons';
      position: absolute;
      bottom: 6px;
      right: 8px;
      z-index: 10;
      font-size: 30px;
      line-height: 38px;
      color: rgba(165, 173, 186, 1);
      pointer-events: none;
    }
  }
  .cwpf_intlgnc_chart_actvtabs {
    text-color: blue;
    border-bottom: 4px solid blue;
    @apply border-b-2 border-blue-100 mx-8;
  }
  
  
  /*section4.  DataPicker */
  .ant-picker-date-panel {
    .ant-picker-date-panel
      .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start
      .ant-picker-cell-inner::after,
    .ant-picker-date-panel
      .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end
      .ant-picker-cell-inner::after {
      background-color: yellow !important;
      color: red !important;
    }
    .ant-picker-header {
      border-bottom: none !important;
    }
    .ant-picker-header-prev-btn .ant-picker-header-super-prev-btn {
      visibility: hidden;
    }
  }
  .ant-picker-body {
    .ant-picker-cell-inner::before {
      border: 1px solid #2f39bf !important;
    }
    .ant-picker-cell {
      padding: 0px !important;
      .ant-picker-cell-inner {
        text-align: center !important;
        min-width: 36px !important;
      }
    }
  }
  
  .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
  .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end)
    .ant-picker-cell-inner {
    background-color: #f4f5f7 !important;
  }
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background-color: #2f39bf !important;
    color: #333;
  }
  .ant-picker-date-panel {
    .ant-picker-prev-icon::before,
    .ant-picker-next-icon::before,
    .ant-picker-super-prev-icon::before,
    .ant-picker-super-next-icon::before {
      visibility: hidden;
    }
    .ant-picker-content th {
      @apply font-medium;
    }
  }
  .ant-picker-header-view {
    button {
      border: 1px solid #f4f5f7 !important;
      padding-left: 16px !important ;
      padding-right: 16px !important;
      margin-top: 8px !important;
      border-radius: 8px !important;
    }
  }
  
  .ant-picker-cell-in-range {
    background-color: #d5d7f2 !important;
  }
  
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single),
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single),
  .ant-picker-cell-in-range {
    &::before {
      box-shadow: none !important;
      background-color: #d5d7f2 !important;
    }
    
  }
  
  
  
  
  .ant-picker-cell-in-range {
    background-color: #d5d7f2 !important;
  }
  
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single),
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single),
  .ant-picker-cell-in-range {
    &::before {
      :hover {
        background-color: #d5d7f2 !important;
      }
      box-shadow: none !important;
      background-color: #d5d7f2 !important;
    }
  }
  
  .ant-picker-cell {
    padding: 0px !important;
    .ant-picker-cell-inner {
      text-align: center !important;
      min-width: 36px !important;
    }
  }