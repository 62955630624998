/**
 * @tableofcontents
 *
 * 1. Plan Added Component
 */

/* @section 1. Plan Added Component */

.cwpf_planAdded_sec {
  background: url('../../../../../../Assets/img/modal/modal-bg.png') no-repeat
    left top;
  position: relative;
  @apply bg-contain;
}

.cwpf_planAdded_sec::before {
  content: '';
  background: url('../../../../../../Assets/img/modal/arrow-up.png') no-repeat
    left top;
  background-size: contain;
  width: 120px;
  height: 108px;
  position: absolute;
  left: 60px;
  top: 90px;
}
