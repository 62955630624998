@mixin common {
  @apply rounded-4 flex items-center justify-center;
}

@mixin iconsStyle($color, $h-color: white, $a-color: white) {
  transition: all 0.3s ease-in-out;
  & > span {
    @apply text-#{$color};
  }
  & > b > svg > path {
    @apply fill-#{$color};
  }
  & > b {
    @apply text-#{$color};
  }
  &:hover {
    & > span {
      @apply text-#{$h-color};
    }
    & > b > svg > path {
      @apply fill-#{$h-color};
    }
    & > b {
      @apply text-#{$h-color};
    }
  }
  &:active {
    & > span {
      @apply text-#{$a-color};
    }
    & > svg > path {
      @apply fill-#{$a-color};
    }
    & > b {
      @apply text-#{$a-color};
    }
  }
}

@mixin default($bg-color, $h-bg-color, $a-bg-color) {
  @apply text-white #{$bg-color} hover:#{$h-bg-color} active:#{$a-bg-color};
  @include common;
  @include iconsStyle(white);
}

@mixin defaultDisable($color, $bg-color) {
  @include common;
  @apply text-#{$color} #{$bg-color};
  @include iconsStyle($color, $color, $color);
}

@mixin outline($color, $b-color, $h-color, $h-bg-color, $a-color, $a-bg-color) {
  @include common;
  @apply text-#{$color} border #{$b-color} hover:text-#{$h-color} hover:#{$h-bg-color} active:text-#{$a-color} active:#{$a-bg-color};
  @include iconsStyle($color, $h-color, $a-color);
}

@mixin outlineDisable($color, $b-color) {
  @include common;
  @apply text-#{$color} border #{$b-color};
  @include iconsStyle($color, $color, $color);
}

@mixin onlyText($color, $h-color, $a-color) {
  @include common;
  @apply text-#{$color} hover:text-#{$h-color} active:text-#{$a-color};
  @include iconsStyle($color, $h-color, $a-color);
}

@mixin onlyTextDisable($color) {
  @include common;
  @apply text-#{$color};
  @include iconsStyle($color, $color, $color);
}

//primary
.cwpf_btn_primary,
.cwpf_btn_primary[type='button'],
.cwpf_btn_primary[type='reset'],
.cwpf_btn_primary[type='submit'] {
  @include default(bg-blue-100, bg-blue-75, bg-gray-80);
}

.cwpf_btn_primary_disable,
.cwpf_btn_primary_disable[type='button'],
.cwpf_btn_primary_disable[type='reset'],
.cwpf_btn_primary_disable[type='submit'] {
  @include defaultDisable(white, bg-blue-25);
}

//secondary
.cwpf_btn_secondary,
.cwpf_btn_secondary[type='button'],
.cwpf_btn_secondary[type='reset'],
.cwpf_btn_secondary[type='submit'] {
  @include default(bg-green-100, bg-green-75, bg-gray-80);
}

.cwpf_btn_secondary_disable,
.cwpf_btn_secondary_disable[type='button'],
.cwpf_btn_secondary_disable[type='reset'],
.cwpf_btn_secondary_disable[type='submit'] {
  @include defaultDisable(gray-30, bg-gray-60);
}

//danger
.cwpf_btn_danger,
.cwpf_btn_danger[type='button'],
.cwpf_btn_danger[type='reset'],
.cwpf_btn_danger[type='submit'] {
  @include default(bg-red-100, bg-red-75, bg-red-50);
}

.cwpf_btn_danger_disable,
.cwpf_btn_danger_disable[type='button'],
.cwpf_btn_danger_disable[type='reset'],
.cwpf_btn_danger_disable[type='submit'] {
  @include defaultDisable(gray-30, bg-red-25);
}

//neutral
.cwpf_btn_neutral,
.cwpf_btn_neutral[type='button'],
.cwpf_btn_neutral[type='reset'],
.cwpf_btn_neutral[type='submit'] {
  @include default(bg-gray-100, bg-gray-80, bg-gray-60);
}

.cwpf_btn_neutral_disable,
.cwpf_btn_neutral_disable[type='button'],
.cwpf_btn_neutral_disable[type='reset'],
.cwpf_btn_neutral_disable[type='submit'] {
  @include defaultDisable(gray-30, bg-gray-3);
}

//primary outline
.cwpf_btn_primary_outline,
.cwpf_btn_primary_outline[type='button'],
.cwpf_btn_primary_outline[type='reset'],
.cwpf_btn_primary_outline[type='submit'] {
  @include outline(
    blue-100,
    border-blue-100,
    white,
    bg-blue-75,
    white,
    bg-gray-80
  );
}

.cwpf_btn_primary_outline_disable,
.cwpf_btn_primary_outline_disable[type='button'],
.cwpf_btn_primary_outline_disable[type='reset'],
.cwpf_btn_primary_outline_disable[type='submit'] {
  @include outlineDisable(blue-25, border-blue-25);
}

//secondary outline
.cwpf_btn_secondary_outline,
.cwpf_btn_secondary_outline[type='button'],
.cwpf_btn_secondary_outline[type='reset'],
.cwpf_btn_secondary_outline[type='submit'] {
  @include outline(
    green-100,
    border-green-100,
    white,
    bg-green-75,
    white,
    bg-gray-80
  );
}

.cwpf_btn_secondary_outline_disable,
.cwpf_btn_secondary_outline_disable[type='button'],
.cwpf_btn_secondary_outline_disable[type='reset'],
.cwpf_btn_secondary_outline_disable[type='submit'] {
  @include outlineDisable(blue-25, border-gray-20);
}

//neutral outline
.cwpf_btn_neutral_outline,
.cwpf_btn_neutral_outline[type='button'],
.cwpf_btn_neutral_outline[type='reset'],
.cwpf_btn_neutral_outline[type='submit'] {
  @include outline(dark, border-dark, white, bg-gray-80, white, bg-gray-60);
}

.cwpf_btn_neutral_outline_disable,
.cwpf_btn_neutral_outline_disable[type='button'],
.cwpf_btn_neutral_outline_disable[type='reset'],
.cwpf_btn_neutral_outline_disable[type='submit'] {
  @include outlineDisable(gray-20, border-gray-30);
}

//danger outline
.cwpf_btn_danger_outline,
.cwpf_btn_danger_outline[type='button'],
.cwpf_btn_danger_outline[type='reset'],
.cwpf_btn_danger_outline[type='submit'] {
  @include outline(red-100, border-red-100, white, bg-red-75, white, bg-red-50);
}

.cwpf_btn_danger_outline_disable,
.cwpf_btn_danger_outline_disable[type='button'],
.cwpf_btn_danger_outline_disable[type='reset'],
.cwpf_btn_danger_outline_disable[type='submit'] {
  @include outlineDisable(red-25, border-gray-20);
}

//primary text
.cwpf_btn_primary_text,
.cwpf_btn_primary_text [type='button'],
.cwpf_btn_primary_text[type='reset'],
.cwpf_btn_primary_text[type='submit'] {
  @include onlyText(blue-100, blue-75, gray-80);
}

.cwpf_btn_primary_text_disable,
.cwpf_btn_primary_text_disable [type='button'],
.cwpf_btn_primary_text_disable[type='reset'],
.cwpf_btn_primary_text_disable[type='submit'] {
  @include onlyTextDisable(blue-25);
}

//secondary text
.cwpf_btn_secondary_text,
.cwpf_btn_secondary_text [type='button'],
.cwpf_btn_secondary_text[type='reset'],
.cwpf_btn_secondary_text[type='submit'] {
  @include onlyText(green-100, green-75, gray-80);
}

.cwpf_btn_secondary_text_disable,
.cwpf_btn_secondary_text_disable [type='button'],
.cwpf_btn_secondary_text_disable[type='reset'],
.cwpf_btn_secondary_text_disable[type='submit'] {
  @include onlyTextDisable(green-25);
}

//neutral text
.cwpf_btn_neutral_text,
.cwpf_btn_neutral_text [type='button'],
.cwpf_btn_neutral_text[type='reset'],
.cwpf_btn_neutral_text[type='submit'] {
  @include onlyText(dark, gray-80, gray-60);
}

.cwpf_btn_neutral_text_disable,
.cwpf_btn_neutral_text_disable [type='button'],
.cwpf_btn_neutral_text_disable[type='reset'],
.cwpf_btn_neutral_text_disable[type='submit'] {
  @include onlyTextDisable(gray-30);
}

//danger text
.cwpf_btn_danger_text,
.cwpf_btn_danger_text [type='button'],
.cwpf_btn_danger_text[type='reset'],
.cwpf_btn_danger_text[type='submit'] {
  @include onlyText(red-100, red-75, red-50);
}

.cwpf_btn_danger_text_disable,
.cwpf_btn_danger_text_disable [type='button'],
.cwpf_btn_danger_text_disable[type='reset'],
.cwpf_btn_danger_text_disable[type='submit'] {
  @include onlyTextDisable(red-25);
}

//spinner
.cwpf_glb_spinner_btn::after {
  content: '';
  margin-right: 0.5rem;
  height: 1rem;
  width: 1rem;
  border-radius: 9999px;
  border-width: 2px;
  animation: spin 2s linear infinite;
  border-top-color: transparent;
  border-left-color: transparent;
  border-bottom-color: currentColor;
  border-right-color: currentColor;
}
