.cwpf_sm_avatar {
  @apply h-[32px] w-[32px] rounded-full;
  background-color: #d5d7f2;
}

.cwpf_md_avatar {
  @apply h-[48px] w-[48px] rounded-full;
  background-color: #d5d7f2;
}

.cwpf_lg_avatar {
  @apply h-[64px] w-[64px] rounded-full;
  background-color: #d5d7f2;
}

.cwpf_xl_avatar {
  @apply h-[96px] w-[96px] rounded-full;
  background-color: #d5d7f2;
}

.cwpf_avatarBorder {
  @apply border-4 border-solid border-gray-3 shadow-md;
  background-color: #d5d7f2;
}

.cwpf_actv_avatar {
  @apply opacity-40;
  background-color: #33434e;
}
