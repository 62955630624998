.cwpf_appdetails_progress {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(250px, 1fr))[auto-fit];
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  -webkit-column-gap: 25px;
          column-gap: 25px;
  row-gap: 25px;
}

.cwpf_grid_cache_dtls_item {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(200px, 1fr))[auto-fit];
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  -webkit-column-gap: 10px;
          column-gap: 10px;
}

.cwpf_cache_dtls_sec {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(400px, 1fr))[auto-fit];
      grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  -webkit-column-gap: 26px;
          column-gap: 26px;
  row-gap: 26px;
}

.cwpf_exclusion_value {
  margin-bottom: 50px;
}

.cwpf_exclusion_value > div > input {
  width: 400px;
  height: 40px;
  padding-left: 12px;
  border: 1px solid #8993a4;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 4px;
  margin-top: 10px;
}

.cwpf_exclusion_value > div:last-child {
  bottom: 90px;
  left: 40px;
}

.cwpf_manage_tabs > ul > li > div > a > svg {
  margin-right: 10px;
}

.cwpf_caching_table {
  @apply text-gray-80;
}

.cwpf_caching_table > thead {
  @apply border-b border-solid border-gray-10;
}

.cwpf_caching_table > thead > tr > th {
  background-color: white;
  @apply normal-case;
}

@media (max-width: 767px) {
  .cwpf_overViewResp {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .cwpf_overViewResp .cwpf_overViewColResp {
    margin-top: 20px;
  }
}
