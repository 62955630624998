.cwpf_manage_tabs {
    .cwpf_manageApp_tabLink {
      @apply text-gray-60 text-16 flex items-center p-0 pb-10 mx-20 transition-none;
      b {
        @apply font-medium;
      }
  
      svg {
        @apply mr-10;
        rect {
          @apply fill-transparent;
        }
  
        path {
          @apply fill-gray-60;
        }
      }
  
      &:hover {
        @apply text-blue-100;
  
        svg {
          path {
            @apply fill-blue-100;
          }
        }
      }
  
      &.disabled {
        &:hover {
          @apply text-gray-60 #{!important};
  
          svg {
            path {
              @apply fill-gray-60;
            }
          }
        }
      }
    }
  
    .cwpf_manage_app_active {
      @apply border-b-blue-100 border-b-2 border-solid;
      b {
        @apply text-blue-100;
      }
  
      svg {
        path {
          @apply fill-blue-100;
        }
      }
    }
  
    .tab {
      @apply relative top-[1px];
    }
    & > ul > li {
      @apply max-lg:border-b max-lg:border-solid max-lg:border-gray-10;
      & > div > a {
        svg {
          margin-right: 10px;
  
          path {
            @apply fill-gray-60;
          }
        }
        &:hover {
          @apply text-blue-100;
          svg > path {
            @apply fill-blue-100;
          }
        }
      }
    }
  
    ul {
      li:not(:last-child) > div {
        margin-right: 30px;
      }
  
      li > div > a {
        padding: 0px;
      }
    }
  }
  
  .cwpf_glb_loader {
    position: relative;
  }
  
  .cwpf_glb_loader::before,
  .cwpf_glb_loader::after {
    content: '';
    @apply inline-block h-30 w-30 border-[5px] rounded-[50%] border-gray-10;
  }
  
  .cwpf_glb_loader::before {
    animation: spin 2s linear infinite;
    border-top-color: transparent;
    border-left-color: transparent;
    @apply border-r-gray-20 border-b-gray-20 absolute z-10;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  @media (max-width: 767px) {
    .cwpf_manageHeaderResp {
      .cwpf_manageHeaderColResp {
        flex: 1;
      }
    }
  }
  
  @media (max-width: 992px) {
    .cwpf_headertabResp {
      width: 50%;
      padding-top: 10px;
    }
  }