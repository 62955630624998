.slideInBottom-enter {
  opacity: 0;
  transform: translateY(100%);
}
.slideInBottom-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.slideInBottom-exit {
  opacity: 1;
}
.slideInBottom-exit-active {
  opacity: 0;
  transform: translateY(100%);
  transition: opacity 300ms, transform 300ms;
}
.fadeIn-enter {
  opacity: 0;
}
.fadeIn-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fadeIn-exit {
  opacity: 1;
}
.fadeIn-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in;
}
.fadeInOnly-enter {
  opacity: 0;
}
.fadeInOnly-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}
.fadeOutOnly-exit {
  opacity: 1;
}
.fadeOutOnly-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in;
}
.fadeInDropdown-enter {
  opacity: 0.8;
}
.fadeInDropdown-enter-enter-active {
  opacity: 1;
  transition: opacity 100ms ease-in;
}

.fadeInDropdown-enter-exit {
  opacity: 1;
}
.fadeInDropdown-enter-exit-active {
  opacity: 0.8;
  transition: opacity 100ms ease-in;
}
