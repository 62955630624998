.tabsNav {
  .tabsVertical {
    li {
      @apply relative h-auto py-12;
      p {
        @apply ml-20;
      }
      &::before {
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        bottom: 0;
        width: 1px;
      }
      a {
        @apply text-gray-100;
        font-weight: 500;
        &.parentItem {
          & + li {
            padding-top: 24px;
          }
        }
      }
      &.active,
      &.parentActive {
        p {
          @apply text-blue-100;
          font-weight: 500;
        }
        &::before {
          @apply bg-blue-100 w-4;
          border-radius: 0 2px 2px 0;
          height: 48px;
        }
        & > a > span {
          @apply text-blue-100;
        }
      }
      &.nonactive {
        p {
          @apply text-gray-60;
          font-weight: 500;
        }
        .nonactive > li {
          @apply bg-purple-100;
        }
        &.active {
          P {
            @apply text-blue-100;
            font-weight: 500;
          }
          &::before {
            @apply w-0;
          }
        }
      }
    }
  }
  .tabsHorizontal {
    @apply space-x-30;
    li {
      @apply relative;
      p {
        @apply pb-6;
        font-weight: 500;
      }
      &::after {
        left: 0;
        right: 0;
        bottom: 0;
        height: 1px;
        position: absolute;
        content: '';
      }
      &.active {
        p {
          @apply text-blue-100;
          font-weight: 500;
        }
        &::after {
          @apply bg-blue-100 h-2;
        }
      }
    }
  }
}
.vertical {
  @apply border-l border-l-gray-40;
}
.horizontal {
  @apply border-b border-b-gray-40;
}
