/* Heading Mixins */

@mixin heading {
    @apply text-heading;
    margin: 0;
}

@mixin h1 {
    @apply text-36;
}

@mixin h2 {
    @apply text-32;
}

@mixin h3 {
    @apply text-28;
}

@mixin h4 {
    @apply text-24;
}

@mixin h5 {
    @apply text-20;
}

@mixin h6 {
    @apply text-18;
}


/* Paragraph Mixins */

@mixin p1 {
    @apply text-16;
}

@mixin p2 {
    @apply text-14;
}

@mixin p3 {
    @apply text-[13px];
}


/* Small Text Mixins */

@mixin s1 {
    @apply text-12;
}

@mixin s2 {
    @apply text-11;
}


/* Headings */

h1,
h2,
h3,
h4,
h5,
h6 {
    @include heading;
}

body h1 {
    @include h1();
}

body h2 {
    @include h2();
}

body h3 {
    @include h3();
}

body h4 {
    @include h4();
}

body h5 {
    @include h5();
}

body h6 {
    @include h6();
}


/* H1 Variations */

.h1-reg {
    @include h1();
    font-weight: 400;
}

.h1-med {
    @include h1();
    font-weight: 500;
}

.h1-semi {
    @include h1();
    font-weight: 600;
}

.h1-bold {
    @include h1();
    font-weight: 700;
}


/* H2 Variations */

.h2-reg {
    @include h2();
    font-weight: 400;
}

.h2-med {
    @include h2();
    font-weight: 500;
}

.h2-semi {
    @include h2();
    font-weight: 600;
}

.h2-bold {
    @include h2();
    font-weight: 700;
}


/* H3 Variations */

.h3-reg {
    @include h3();
    font-weight: 400;
}

.h3-med {
    @include h3();
    font-weight: 500;
}

.h3-semi {
    @include h3();
    font-weight: 600;
}

.h3-bold {
    @include h3();
    font-weight: 700;
}


/* H4 Variations */

.h4-reg {
    @include h4();
    font-weight: 400;
}

.h4-med {
    @include h4();
    font-weight: 500;
}

.h4-semi {
    @include h4();
    font-weight: 600;
}

.h4-bold {
    @include h4();
    font-weight: 700;
}


/* H5 Variations */

.h5-reg {
    @include h5();
    font-weight: 400;
}

.h5-med {
    @include h5();
    font-weight: 500;
}

.h5-semi {
    @include h5();
    font-weight: 600;
}

.h5-bold {
    @include h5();
    font-weight: 700;
}


/* H6 Variations */

.h6-reg {
    @include h6();
    font-weight: 400;
}

.h6-med {
    @include h6();
    font-weight: 500;
}

.h6-semi {
    @include h6();
    font-weight: 600;
}

.h6-bold {
    @include h6();
    font-weight: 700;
}


/* Paragraph */

p,
.p1,
.p2,
.p3 {
    @include p1();
    @apply text-gray-80 m-0;
}

.p2 {
    @include p2();
}

.p3 {
    @include p3();
}


/* P1 Variations */

.p1-med {
    @include p1();
    font-weight: 500;
}

.p1-semi {
    @include p1();
    font-weight: 600;
}

.p1-bold {
    @include p1();
    font-weight: 700;
}


/* P2 Variations */

.p2-med {
    @include p2();
    font-weight: 500;
}

.p2-semi {
    @include p2();
    font-weight: 600;
}

.p2-bold {
    @include p2();
    font-weight: 700;
}


/* P3 Variations */

.p3-med {
    @include p3();
    font-weight: 500;
}

.p3-semi {
    @include p3();
    font-weight: 600;
}

.p3-bold {
    @include p3();
    font-weight: 700;
}

b {
    font-size: inherit;
    color: inherit;
    font-weight: 700;
}