.cwpf_appdetails_progress {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  column-gap: 25px;
  row-gap: 25px;
}
.cwpf_grid_cache_dtls_item {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  column-gap: 10px;
}
.cwpf_cache_dtls_sec {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  column-gap: 26px;
  row-gap: 26px;
}
.cwpf_exclusion_value {
  margin-bottom: 50px;
  & > div > input {
    width: 400px;
    height: 40px;
    padding-left: 12px;

    border: 1px solid #8993a4;
    box-sizing: border-box;
    border-radius: 4px;
    margin-top: 10px;
  }
  & > div:last-child {
    bottom: 90px;
    left: 40px;
  }
}
.cwpf_manage_tabs {
  & > ul > li > div > a > svg {
    margin-right: 10px;
  }
}

.cwpf_caching_table {
  @apply text-gray-80;
  & > thead {
    @apply border-b border-solid border-gray-10;
    & > tr > th {
      background-color: white;
      @apply normal-case;
    }
  }
}
@media (max-width: 767px) {
  .cwpf_overViewResp {
    flex-direction: column;
    .cwpf_overViewColResp {
      margin-top: 20px;
    }
  }
}
