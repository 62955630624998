/**
 * @tableofcontents
 *
 * 1. FAQs
 */

/* @section 1. FAQs */
.cwPltfrm_accordion
{
    height: 0;
    overflow: hidden;
    transition: max-height 1s ease-in-out;
}
