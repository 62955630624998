.Toastify__toast {
  padding: 0px !important;
  min-height: 48px !important;
  max-height: 320px !important;
  -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 6px !important;
}

.Toastify__toast .Toastify__toast-body {
  padding: 0px !important;
  margin: 0;
  -webkit-box-align: stretch !important;
      -ms-flex-align: stretch !important;
          align-items: stretch !important;
}

.Toastify__toast .Toastify__toast-body > div {
  @apply flex items-center space-x-10;
}

.Toastify__toast--success {
  border: 1px solid #09ebaf !important;
}

.Toastify__toast--info {
  border: 1px solid #2f39bf !important;
}

.Toastify__toast--error {
  border: 1px solid #ea4545 !important;
}

.Toastify__toast--warning {
  border: 1px solid #fbd808 !important;
}

.Toastify__close-button {
  padding: 10px 12px 0px 12px !important;
}

.Toastify__close-button > svg {
  width: 20px !important;
  height: 20px !important;
}
