/**
 * @tableofcontents
 *
 * 1. FAQs
 */

/* @section 1. FAQs */
.cwPltfrm_faq_ans
{
    max-height: 0;
    overflow: hidden;
    transition: max-height 1s ease-in-out;
}

.cwPltfrm_faq_ans_actv
{
    max-height: 500px;
    transition: max-height 1s ease-in-out;
}