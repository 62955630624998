.row-gutter-8 {
  margin-right: -4px;
  margin-left: -4px;
}

.row-gutter-8 > .col {
  padding-right: 4px;
  padding-left: 4px;
}

.row-gutter-16 {
  margin-right: -8px;
  margin-left: -8px;
}

.row-gutter-16 > .col {
  padding-right: 8px;
  padding-left: 8px;
}

.row-gutter-24 {
  margin-right: -12px;
  margin-left: -12px;
}

.row-gutter-24 > .col {
  padding-right: 12px;
  padding-left: 12px;
}

.row-gutter-32 {
  margin-right: -16px;
  margin-left: -16px;
}

.row-gutter-32 > .col {
  padding-right: 16px;
  padding-left: 16px;
}

.row-gutter-40 {
  margin-right: -20px;
  margin-left: -20px;
}

.row-gutter-40 > .col {
  padding-right: 20px;
  padding-left: 20px;
}

@media (min-width: 1199px) {
  .col-lg-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.333%;
            flex: 0 0 8.333%;
    max-width: 8.333%;
  }
  .col-lg-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.666%;
            flex: 0 0 16.666%;
    max-width: 16.666%;
  }
  .col-lg-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.333%;
            flex: 0 0 33.333%;
    max-width: 33.333%;
  }
  .col-lg-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.666%;
            flex: 0 0 41.666%;
    max-width: 41.666%;
  }
  .col-lg-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.333%;
            flex: 0 0 58.333%;
    max-width: 58.333%;
  }
  .col-lg-8 {
    -webkit-box-flex: 66.666%;
        -ms-flex: 66.666%;
            flex: 66.666%;
    max-width: 66.666%;
  }
  .col-lg-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.333%;
            flex: 0 0 83.333%;
    max-width: 83.333%;
  }
  .col-lg-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.666%;
            flex: 0 0 91.666%;
    max-width: 91.666%;
  }
  .col-lg-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (max-width: 1199px) {
  .col-lg-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.333%;
            flex: 0 0 8.333%;
    max-width: 8.333%;
  }
  .col-lg-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.666%;
            flex: 0 0 16.666%;
    max-width: 16.666%;
  }
  .col-lg-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.333%;
            flex: 0 0 33.333%;
    max-width: 33.333%;
  }
  .col-lg-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.666%;
            flex: 0 0 41.666%;
    max-width: 41.666%;
  }
  .col-lg-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.333%;
            flex: 0 0 58.333%;
    max-width: 58.333%;
  }
  .col-lg-8 {
    -webkit-box-flex: 66.666%;
        -ms-flex: 66.666%;
            flex: 66.666%;
    max-width: 66.666%;
  }
  .col-lg-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.333%;
            flex: 0 0 83.333%;
    max-width: 83.333%;
  }
  .col-lg-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.666%;
            flex: 0 0 91.666%;
    max-width: 91.666%;
  }
  .col-lg-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (max-width: 992px) {
  .col-md-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.333%;
            flex: 0 0 8.333%;
    max-width: 8.333%;
  }
  .col-md-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.666%;
            flex: 0 0 16.666%;
    max-width: 16.666%;
  }
  .col-md-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.333%;
            flex: 0 0 33.333%;
    max-width: 33.333%;
  }
  .col-md-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.666%;
            flex: 0 0 41.666%;
    max-width: 41.666%;
  }
  .col-md-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.333%;
            flex: 0 0 58.333%;
    max-width: 58.333%;
  }
  .col-md-8 {
    -webkit-box-flex: 66.666%;
        -ms-flex: 66.666%;
            flex: 66.666%;
    max-width: 66.666%;
  }
  .col-md-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.333%;
            flex: 0 0 83.333%;
    max-width: 83.333%;
  }
  .col-md-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.666%;
            flex: 0 0 91.666%;
    max-width: 91.666%;
  }
  .col-md-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (max-width: 767px) {
  .col-sm-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.333%;
            flex: 0 0 8.333%;
    max-width: 8.333%;
  }
  .col-sm-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.666%;
            flex: 0 0 16.666%;
    max-width: 16.666%;
  }
  .col-sm-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.333%;
            flex: 0 0 33.333%;
    max-width: 33.333%;
  }
  .col-sm-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.666%;
            flex: 0 0 41.666%;
    max-width: 41.666%;
  }
  .col-sm-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.333%;
            flex: 0 0 58.333%;
    max-width: 58.333%;
  }
  .col-sm-8 {
    -webkit-box-flex: 66.666%;
        -ms-flex: 66.666%;
            flex: 66.666%;
    max-width: 66.666%;
  }
  .col-sm-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.333%;
            flex: 0 0 83.333%;
    max-width: 83.333%;
  }
  .col-sm-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.666%;
            flex: 0 0 91.666%;
    max-width: 91.666%;
  }
  .col-sm-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (max-width: 500px) {
  .col-xs-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.333%;
            flex: 0 0 8.333%;
    max-width: 8.333%;
  }
  .col-xs-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.666%;
            flex: 0 0 16.666%;
    max-width: 16.666%;
  }
  .col-xs-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xs-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.333%;
            flex: 0 0 33.333%;
    max-width: 33.333%;
  }
  .col-xs-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.666%;
            flex: 0 0 41.666%;
    max-width: 41.666%;
  }
  .col-xs-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xs-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.333%;
            flex: 0 0 58.333%;
    max-width: 58.333%;
  }
  .col-xs-8 {
    -webkit-box-flex: 66.666%;
        -ms-flex: 66.666%;
            flex: 66.666%;
    max-width: 66.666%;
  }
  .col-xs-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xs-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.333%;
            flex: 0 0 83.333%;
    max-width: 83.333%;
  }
  .col-xs-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.666%;
            flex: 0 0 91.666%;
    max-width: 91.666%;
  }
  .col-xs-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
}
