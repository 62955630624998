/**
 * @tableofcontents
 *
 * 1. Global Header
 */


/* @section 1. Global Header */

.cwpf_glbHdr_wrap {
    @apply bg-white fixed border-b border-gray-10 w-[100%] left-0 top-0 h-[48px];
    z-index: 999;
    .cwpf_glbHdr_logoBox {
        @apply py-12 px-24 border-r border-gray-10;
    }
    // .cwpf_glbHdr_rhtBox {
    //   @apply flex items-center;
    // }
    .cwpf_glbHdr_searchBox {
        @apply relative px-20 pt-[4px] pb-12 border-x border-gray-10;
        input[type='search'] {
            @apply outline-[0];
        }
    }
    .cwpf_glbHdr_userHandler {
        @apply px-20 py-8 border-r border-gray-10;
        b,
        span {
            @apply text-14 text-gray-60 font-normal inline-block align-middle;
        }
        span {
            @apply text-22 text-gray-30 ml-10;
        }
    }
    .cwpf_glbHdr_support {
        @apply px-16 py-14;
        span {
            @apply text-22 text-gray-30 relative top-8;
        }
    }
}

.cwpf_glbHdr_drpdwn {
    @apply flex flex-col bg-white absolute items-start justify-evenly right-[0] top-[100%] w-[186px] border border-[#C1C7D0] border-solid rounded-4 cursor-pointer z-10;
}