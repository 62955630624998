.cwpf_manage_tabs .cwpf_manageApp_tabLink {
  @apply text-gray-60 text-16 flex items-center p-0 pb-10 mx-20 transition-none;
}

.cwpf_manage_tabs .cwpf_manageApp_tabLink b {
  @apply font-medium;
}

.cwpf_manage_tabs .cwpf_manageApp_tabLink svg {
  @apply mr-10;
}

.cwpf_manage_tabs .cwpf_manageApp_tabLink svg rect {
  @apply fill-transparent;
}

.cwpf_manage_tabs .cwpf_manageApp_tabLink svg path {
  @apply fill-gray-60;
}

.cwpf_manage_tabs .cwpf_manageApp_tabLink:hover {
  @apply text-blue-100;
}

.cwpf_manage_tabs .cwpf_manageApp_tabLink:hover svg path {
  @apply fill-blue-100;
}

.cwpf_manage_tabs .cwpf_manageApp_tabLink.disabled:hover {
  @apply text-gray-60 !important;
}

.cwpf_manage_tabs .cwpf_manageApp_tabLink.disabled:hover svg path {
  @apply fill-gray-60;
}

.cwpf_manage_tabs .cwpf_manage_app_active {
  @apply border-b-blue-100 border-b-2 border-solid;
}

.cwpf_manage_tabs .cwpf_manage_app_active b {
  @apply text-blue-100;
}

.cwpf_manage_tabs .cwpf_manage_app_active svg path {
  @apply fill-blue-100;
}

.cwpf_manage_tabs .tab {
  @apply relative top-[1px];
}

.cwpf_manage_tabs > ul > li {
  @apply max-lg:border-b max-lg:border-solid max-lg:border-gray-10;
}

.cwpf_manage_tabs > ul > li > div > a svg {
  margin-right: 10px;
}

.cwpf_manage_tabs > ul > li > div > a svg path {
  @apply fill-gray-60;
}

.cwpf_manage_tabs > ul > li > div > a:hover {
  @apply text-blue-100;
}

.cwpf_manage_tabs > ul > li > div > a:hover svg > path {
  @apply fill-blue-100;
}

.cwpf_manage_tabs ul li:not(:last-child) > div {
  margin-right: 30px;
}

.cwpf_manage_tabs ul li > div > a {
  padding: 0px;
}

.cwpf_glb_loader {
  position: relative;
}

.cwpf_glb_loader::before,
.cwpf_glb_loader::after {
  content: '';
  @apply inline-block h-30 w-30 border-[5px] rounded-[50%] border-gray-10;
}

.cwpf_glb_loader::before {
  -webkit-animation: spin 2s linear infinite;
          animation: spin 2s linear infinite;
  border-top-color: transparent;
  border-left-color: transparent;
  @apply border-r-gray-20 border-b-gray-20 absolute z-10;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@media (max-width: 767px) {
  .cwpf_manageHeaderResp .cwpf_manageHeaderColResp {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
  }
}

@media (max-width: 992px) {
  .cwpf_headertabResp {
    width: 50%;
    padding-top: 10px;
  }
}
