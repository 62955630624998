/* @section 2. header */
.cwpf_hm_bnr_sec {
  background: url('../../../../../Assets/img/cw_platform_bg.png') no-repeat
    center top #2f39bf;
  background-size: cover;
}

.cwpf_hm_hdr_drpdwn {
  right: 0 !important;
}

/* @section 3. Find Plan Slider */
.cwpf_slctPlan_sldrBox {
  .cwpf_slctPlan_sldrInput {
    transition: all 0.3s ease-in-out;
    @apply bg-[transparent] rounded-20 h-14 outline-0 appearance-none z-[999] absolute;

    &::-webkit-slider-thumb {
      @apply appearance-none w-40 h-40 rounded-[50%] bg-[transparent] relative z-[99] cursor-pointer opacity-0 left-[-10px];
    }

    &::-moz-range-thumb {
      @apply appearance-none w-40 h-40 rounded-[50%] bg-[transparent] relative z-[99] cursor-pointer opacity-0 left-[-10px];
    }
  }

  .cwpf_slctPlan_sldrThumb {
    transition: all 0.3s ease-in-out;
    @apply w-36 h-36 rounded-[50%] absolute cursor-pointer z-[99] top-[-28px] border-4 border-solid border-light;
    background: url('../../../../../Assets/img/range-slider-arrow.svg')
      no-repeat center center #222e37;
    &::before,
    &::after {
      display: none;
    }
  }

  .cwpf_slctPlan_error_tooltip {
    .cwpf_slctPlan_sldrThumb.tooltip {
      &::before {
        @apply bg-blue-75 text-14 font-semibold px-10 py-4 rounded-18;
        display: inline-block !important;
      }

      &::after {
        display: inline-block !important;
        @apply border-b-blue-75 inline-block;
      }
    }
  }
}

.cwpf_slctPlan_sldrbox_error {
  .cwpf_slctPlan_error_tooltip {
    .cwpf_slctPlan_sldrThumb.tooltip {
      &::before {
        @apply bg-red-100;
      }

      &::after {
        @apply border-b-red-100;
      }
    }
  }
}

.cwpf_slctPlan_statsBox {
  ul {
    li {
      b {
        @apply text-30 text-heading font-semibold;
        display: block;
      }

      span {
        @apply text-20 text-heading;
      }
    }
  }
}

.cwpf_slctPlan_sldrSteps {
  transition: all 0.3s ease-in-out;
  @apply relative bg-blue-25 rounded-20 h-14 outline-0 appearance-none;
  background-image: linear-gradient(#363cd0, #363cd0);
  background-size: 0% 100%;
  background-repeat: no-repeat;

  &::before,
  &::after {
    content: '';
    @apply absolute w-[6px] h-[6px] bg-light rounded-[100%] top-[50%] left-8 translate-y-[-50%];
  }

  &::after {
    @apply left-[auto] right-8;
  }
}

.cwpf_slctPlan_ctaBox {
  button {
    @apply w-full;
  }
}

.cwpf_slctPlan_noteBox {
  ul {
    & > *:not(:last-child) {
      @apply mb-18;
    }
    li {
      @apply text-18 text-gray-80;

      &::before {
        content: '\e5ca';
        font-family: 'Material Icons';
        @apply text-20 text-blue-100 relative top-[5px] mr-10;
      }
    }
  }
}

.cwpf_slctPlan_wrap {
  @apply shadow-[0px_0px_14px_rgba(207,207,207,0.25)] rounded-6 bg-white relative;
}

.cwpf_slctPlan_rhtBox {
  background: url('../../../../../Assets/img/support-img.png') no-repeat center
    bottom;
  @apply bg-gray-3 px-30 py-40 rounded-r-6;
}

/* @section 4. Other Features */
.cwpf_hm_feat_list {
  display: flex;
  flex-flow: row wrap;
  li {
    @apply text-gray-80;
    flex: 0 0 50%;
    margin-bottom: 20px;
    position: relative;
    padding-left: 60px;
    &::before {
      content: '';
      width: 50px;
      height: 30px;
      position: absolute;
      left: 0;
      top: -3px;
      display: inline-block;
      background: url('../../../../../Assets/img/platform-feat.png') no-repeat
        left top;
    }

    &:nth-of-type(2)::before {
      background-position: left -69px;
    }

    &:nth-of-type(3)::before {
      background-position: left -146px;
    }

    &:nth-of-type(4)::before {
      background-position: left -223px;
    }

    &:nth-of-type(5)::before {
      background-position: left -299px;
    }
  }
}

/* @section 5. Customer Testimonial */
.cwpf_tstml_sec {
  background: url('../../../../../Assets/img/cwPltrfm_lft_img.png') no-repeat
      left bottom,
    url('../../../../../Assets/img/cwPltrfm_rht_img.png') no-repeat right 50px;
}

.cwpf_tstml_box {
  .material-icons {
    font-size: 40px;
    margin: 30px 0;
  }
}

@media (max-width: 1600px) {
  .cwpf_slctPlan_sec {
    .container {
      max-width: 1100px;
    }
  }
}
