.cwpf_userApp_screenShot {
  @apply relative;
}
@media (max-width: 992px) {
  .cwpf_shortcutsResp {
    @apply flex-col;
  }
}
@media (max-width: 767px) {
  .cwpf_overviewListResp {
    @apply flex-col;
  }
  .cwpf_spacingResp {
    @apply mb-10;
  }
  .cwpf_btnSpacingResp {
    margin-right: 0 !important;
  }
  .cwpf_AppNameSpacingResp {
    justify-content: space-between;
  }
  .cwpf_centerResp {
    text-align: center;
  }
  .cwpf_shortcutsRowResp {
    @apply flex-wrap;
  }
}
