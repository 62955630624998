/**
 * @tableofcontents
 *
 * 1. Application Listing
 */

/* @section 1. Application Listing */

.cwpf_lstng_hdr_btn {
    @apply py-8 px-16;
  }
  
  .cwpf_appView_polling {
    .cwpf_userApp_screenShot {
      @apply relative;
    }
  
    .cwpf_appView_pollingloader {
      @apply text-center absolute w-full h-full top-0 left-0 p-10 z-10 flex flex-col items-center justify-center overflow-hidden bg-gray-3;
      p {
        @apply text-12 text-white;
      }
  
      .cwpf_appView_pollingloaderBar {
        @apply w-full h-[8px] bg-white overflow-hidden rounded-10 relative;
  
        span {
          @apply w-full h-full bg-green-100 absolute left-0 top-0 rounded-10;
          animation: progressBarAnm 1s infinite linear;
          transform-origin: 0% 50%;
        }
      }
    }
  }
  
  .cwpf_appView_gridWrap {
    .cwpf_appView_pollingloader {
      .cwpf_appView_pollingloaderBar {
        width: 70% !important;
        height: 10px !important;
      }
    }
  }
  
  @keyframes progressBarAnm {
    0% {
      transform: translateX(0) scaleX(0);
    }
    40% {
      transform: translateX(0) scaleX(0.4);
    }
    100% {
      transform: translateX(100%) scaleX(0.5);
    }
  }
  
  .cwpf_appView_listBox {
    transition: all 0.3s ease-in-out;
    @apply cursor-pointer;
    &:hover {
      @apply bg-gray-3;
    }
  }
  .cwpf_appView_listBox,
  .cwpf_appView_gribBox {
    .cwpf_app_type_img {
      width: 30px;
    }
  
    .cwpf_app_type_wordpress {
      width: 20px !important;
    }
  
    .cwpf_app_type_woocommerce {
      width: 30px;
    }
  }
  .cwpf_appView_listBox_install {
    transition: all 0.3s ease-in-out;
    &:hover {
      @apply bg-gray-3;
    }
  }
  
  .cwpf_userApp_gridScreenShot {
    &:hover {
      .cwpf_userApp_gridHover {
        transition: all 0.3s ease-in-out;
        @apply opacity-100;
        @apply rounded-b-[0px] rounded-t-4;
      }
    }
  }
  
  // .cwpf_userApp_gridScreenShotRow {
  //   &:hover {
  //     .cwpf_userApp_gridHover {
  //       @apply opacity-100;
  //       @apply rounded-b-[0px] rounded-t-4;
  //     }
  //   }
  // }
  
  .cwpf_appView_emptyWrap {
    @apply flex items-center justify-center text-center absolute left-0 top-0 w-full h-full z-[-9];
  }
  
  .cwpf_listingItemGrid_cname {
    @apply text-12 leading-3 text-gray-80 hover:text-blue-100 max-w-[60%] text-ellipsis overflow-hidden whitespace-nowrap inline-block align-middle;
  }
  
  .cwpf_listingItemGrid_region {
    @apply leading-3 text-12 text-gray-80 max-w-[60%] text-ellipsis overflow-hidden whitespace-nowrap inline-block align-middle;
  }
  .cwpf_listingItem_warningIcon {
    @apply absolute top-0 left-0 w-full h-full bg-gray-80 opacity-80 flex items-center justify-center;
  }
  
  .cwpf_listingItem_region {
    @apply text-gray-80 max-w-[100px] text-ellipsis overflow-hidden whitespace-nowrap;
  }
  
  .cwpf_listingItem_cname {
    @apply text-gray-80 hover:text-blue-100 max-w-[150px] text-ellipsis overflow-hidden whitespace-nowrap text-left inline-block;
  }
  
  .cwpf_listingItem_mainRow {
    @apply relative border border-solid border-gray-3 rounded-4 max-lg:flex-col max-lg:border-none max-lg:pb-20;
  }
  
  .cwpf_listingItemPolling_spinner {
    @apply bg-gray-3 lg:mr-[26px] w-[167px] h-[120px] max-lg:w-[100%] flex items-center justify-center;
  }
  
  .cwpf_listingItemPolling_region {
    @apply text-gray-80 w-[100px] text-ellipsis overflow-hidden whitespace-nowrap;
  }
  
  .cwpf_listingItemPolling_mainRow {
    @apply border border-solid border-gray-3 rounded-4 max-lg:flex-col max-lg:border-none max-lg:pb-20;
  }
  
  .cwpf_listingItemException_mainRow {
    @apply border border-solid border-gray-3 rounded-4 max-lg:flex-col max-lg:border-none max-lg:pb-20;
  }
  
  .cwpf_listingItem_card {
    @apply min-w-[356px] border-gray-10 bg-light rounded-4 fixed bottom-0 z-[100] left-[65%];
  }
  @media (max-width: 766px) {
    .cwpf_appView_listWrap {
      display: block !important;
    }
  
    .cwpf_appView_gridWrap {
      display: block !important;
    }
  }
  
  // .cwpf_status_card {
  //   display: none;
  //   &.visible {
  //     display: block;
  //   }
  // }
  //listing app card
  .cwpf_listingapp_card {
    li {
      // &::before {
      //   content: '\e917';
      //   font-family: 'Material Icons';
      //   @apply text-20 text-blue-100 relative top-[4px] mr-10;
      // }
  
      // &.completed {
      //   &::before {
      //     content: '\e92d';
      //     font-family: 'Material Icons';
      //     @apply text-20 text-green-100 relative top-[4px] mr-10;
      //   }
      // }
    }
  }
  
  //application installin spinner
  // .cwpf_appinstall_spinner {
  //   content: '';
  //   margin-right: 0.5rem;
  //   height: 2rem;
  //   width: 2rem;
  //   border-radius: 9999px;
  //   border-width: 2px;
  //   animation: spin 2s linear infinite;
  //   border-top-color: transparent;
  //   border-left-color: transparent;
  //   border-bottom-color: currentColor;
  //   border-right-color: currentColor;
  //   position: relative;
  //   left: 40%;
  //   top: 40%;
  // }
  
  //Application install spinner
  .cwpf_appinstall_spinner::before,
  .cwpf_appinstall_spinner::after {
    content: '';
    @apply inline-block h-40 w-40 border-4 rounded-[50%] border-gray-10;
  }
  
  .cwpf_appinstall_spinner::before {
    animation: spin 2s linear infinite;
    border-top-color: transparent;
    border-left-color: transparent;
    @apply border-r-gray-20 border-b-gray-20 absolute z-10;
  }
  
  .cwpf_installList_loader::before {
    content: '';
    @apply inline-block h-16 w-16 border-2 rounded-[50%] mr-10 relative top-[2px] border-t-transparent border-b-transparent border-r-blue-100 border-b-blue-100  border-l-blue-100;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  .appdetailscard-enter {
    opacity: 0;
    transform: translateY(100%);
  }
  .appdetailscard-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
  }
  .appdetailscard-exit {
    opacity: 1;
  }
  .appdetailscard-exit-active {
    opacity: 0;
    transform: translateY(100%);
    transition: opacity 300ms, transform 300ms;
  }
  
  .item-enter {
    opacity: 0;
  }
  .item-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
  }
  .item-exit {
    opacity: 1;
  }
  .item-exit-active {
    opacity: 0;
    transition: opacity 500ms ease-in;
  }