.cwpf_userApp_screenShot {
  @apply relative;
}

@media (max-width: 767px) {
  .cwpf_wpUpdates {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
