.cwpf_intelligence_sftplogs_box {
  @apply py-10 relative shadow;
  // &::before {
  //   content: '';
  //   width: 0px;
  //   height: 0px;
  //   border-style: solid;
  //   border-width: 18px 15px 0px 0;
  //   border-color: transparent #cccccc transparent transparent;
  //   position: absolute;
  //   left: -16px;
  //   top: -2px;
  // }
}
.cwpf_log_table {
  @apply px-20;
  tbody {
    @apply border-b-gray-10 border-b-2;
    &:last-child {
      @apply border-gray-10 border-b;
    }
    tr {
      td {
        @apply bg-white font-normal py-10 px-24;
      }
      .cwpf_logsDetail {
        white-space: normal;
      }
    }
  }
}
.cwpf_intelligence_logDtl_mainBox {
  @apply relative pt-40;
  .cwpf_intelligence_sftpLogs_dateBox {
    @apply bg-gray-3 text-blue-100 px-20 py-8 z-10 absolute left-[0px] top-0;
  }

  .cwpf_intelligence_logDtl_inrBox {
    @apply relative pl-30 pb-40;

    .cwpf_intelligence_logDtl_point {
      @apply w-18 h-18 rounded-[50%] bg-light inline-block absolute left-[-9px] top-0 p-[5px];

      &::before {
        content: '';
        @apply w-[60%] h-[60%] rounded-[50%] border-2 border-blue-100 border-solid bg-light absolute left-[5px] top-[3px];
      }
    }

    .cwpf_intelligence_logDtl_timeBox {
      @apply absolute left-[-65px] top-[-1px];
    }

    &::before,
    &:first-child::after {
      content: '';
      @apply w-[2px] h-[100%] bg-gray-20 inline-block absolute left-0 top-0;
    }

    &:first-child {
      @apply mt-40;

      &::after {
        top: -40px;
        height: 40px;
      }
    }
  }
}
.cwpf_errorlog_search {
  width: 234px;
  transition: width 300ms;
}

.cwpf_logs_searchBox {
  transition: all 0.3s ease-in-out;
  background: url('../../../../../../../Assets/img/manage-app/Intelligence/searchIcon.svg')
    no-repeat 10px 2px;
  cursor: pointer;
  &:focus {
    @apply border-gray-20 w-[250px];
  }
}
