/**
 * @tableofcontents
 *
 * 1. Side Navigatio Panel
 */

/* @section 1. Side Navigatio Panel */

.cwpf_sideNav_wrap {
  @apply bg-white fixed top-0 right-0 left-0 bottom-0  h-[100%] w-[48px] overflow-hidden;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  transition: width 0.3s linear;
  z-index: 99;

  ul {
    li {
      @apply relative min-w-[210px] inline-block my-14 px-12;
      a {
        @apply flex items-center;
        span {
          @apply mr-20 text-gray-40;
        }
        b {
          @apply text-14 text-gray-80 font-normal opacity-0;
        }

        &:not(.cwpf_disable_link):hover {
          b {
            @apply text-blue-100;
          }

          svg {
            path {
              fill: #2f39bf;
            }
          }
        }
        &.cwpf_active_link {
          b {
            @apply text-blue-100;
          }

          svg {
            path {
              fill: #2f39bf;
            }
          }
        }
      }
    }
  }

  &:hover {
    @apply w-[210px] overflow-visible;

    ul {
      li {
        b {
          @apply opacity-100;
        }
      }
    }

    .cwpf_sideNav_bottom {
      li {
        a {
          b {
            opacity: 1;
            visibility: visible;
            transition: opacity 0.1s linear 0.2s;
          }
        }
      }
      .cwpf_old_route {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .cwpf_sideNav_top {
    @apply mt-[100px] overflow-x-hidden overflow-y-auto;
    li {
      a {
        svg {
          @apply mr-20;
        }
      }
    }
  }

  .cwpf_sideNav_bottom {
    li {
      a {
        img {
          @apply mr-[5px];
        }

        b {
          @apply text-dark font-medium uppercase;
          opacity: 0;
          visibility: hidden;

          span {
            @apply text-[10px] text-gray-80 block;
          }
        }
      }
    }

    /*.cwpf_old_route {
        opacity: 0;
        visibility: hidden;
      }*/
  }
}
