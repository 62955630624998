.tabsNav .tabsVertical li {
  @apply relative h-auto py-12;
}

.tabsNav .tabsVertical li p {
  @apply ml-20;
}

.tabsNav .tabsVertical li::before {
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  bottom: 0;
  width: 1px;
}

.tabsNav .tabsVertical li a {
  @apply text-gray-100;
  font-weight: 500;
}

.tabsNav .tabsVertical li a.parentItem + li {
  padding-top: 24px;
}

.tabsNav .tabsVertical li.active p, .tabsNav .tabsVertical li.parentActive p {
  @apply text-blue-100;
  font-weight: 500;
}

.tabsNav .tabsVertical li.active::before, .tabsNav .tabsVertical li.parentActive::before {
  @apply bg-blue-100 w-4;
  border-radius: 0 2px 2px 0;
  height: 48px;
}

.tabsNav .tabsVertical li.active > a > span, .tabsNav .tabsVertical li.parentActive > a > span {
  @apply text-blue-100;
}

.tabsNav .tabsVertical li.nonactive p {
  @apply text-gray-60;
  font-weight: 500;
}

.tabsNav .tabsVertical li.nonactive .nonactive > li {
  @apply bg-purple-100;
}

.tabsNav .tabsVertical li.nonactive.active P {
  @apply text-blue-100;
  font-weight: 500;
}

.tabsNav .tabsVertical li.nonactive.active::before {
  @apply w-0;
}

.tabsNav .tabsHorizontal {
  @apply space-x-30;
}

.tabsNav .tabsHorizontal li {
  @apply relative;
}

.tabsNav .tabsHorizontal li p {
  @apply pb-6;
  font-weight: 500;
}

.tabsNav .tabsHorizontal li::after {
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  position: absolute;
  content: '';
}

.tabsNav .tabsHorizontal li.active p {
  @apply text-blue-100;
  font-weight: 500;
}

.tabsNav .tabsHorizontal li.active::after {
  @apply bg-blue-100 h-2;
}

.vertical {
  @apply border-l border-l-gray-40;
}

.horizontal {
  @apply border-b border-b-gray-40;
}
