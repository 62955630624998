.notification-container {
  box-sizing: border-box;
  position: fixed;
  z-index: 99999;
}

.top-right {
  top: 12px;
  right: 12px;
  transition: transform 0.6s ease-in-out;
  animation: toast-in-right 0.7s;
}

.bottom-right {
  bottom: 12px;
  right: 12px;
  transition: transform 0.6s ease-in-out;
  animation: toast-in-right 0.7s;
}

.top-left {
  top: 12px;
  left: 12px;
  transition: transform 0.6s ease-in;
  animation: toast-in-left 0.7s;
}

.bottom-left {
  bottom: 12px;
  left: 12px;
  transition: transform 0.6s ease-in;
  animation: toast-in-left 0.7s;
}

.notification {
  transition: 0.3s ease;
  pointer-events: auto;

  @apply text-gray-80 overflow-hidden relative mb-30 w-[320px] h-[48px]  flex items-center bg-white justify-between shadow-[0px_4px_4px_rgba(0,0,0,0.25)] border border-solid rounded-6;
}

@keyframes toast-in-right {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes toast-in-left {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
