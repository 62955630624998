.row-gutter-8 {
  margin-right: -4px;
  margin-left: -4px;
}

.row-gutter-8 > .col {
  padding-right: 4px;
  padding-left: 4px;
}

.row-gutter-16 {
  margin-right: -8px;
  margin-left: -8px;
}

.row-gutter-16 > .col {
  padding-right: 8px;
  padding-left: 8px;
}

.row-gutter-24 {
  margin-right: -12px;
  margin-left: -12px;
}

.row-gutter-24 > .col {
  padding-right: 12px;
  padding-left: 12px;
}

.row-gutter-32 {
  margin-right: -16px;
  margin-left: -16px;
}

.row-gutter-32 > .col {
  padding-right: 16px;
  padding-left: 16px;
}

.row-gutter-40 {
  margin-right: -20px;
  margin-left: -20px;
}

.row-gutter-40 > .col {
  padding-right: 20px;
  padding-left: 20px;
}

@mixin flexBox($screenSize) {
  #{$screenSize}-1 {
    flex: 0 0 8.333%;
    max-width: 8.333%;
  }
  #{$screenSize}-2 {
    flex: 0 0 16.666%;
    max-width: 16.666%;
  }
  #{$screenSize}-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  #{$screenSize}-4 {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
  #{$screenSize}-5 {
    flex: 0 0 41.666%;
    max-width: 41.666%;
  }
  #{$screenSize}-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  #{$screenSize}-7 {
    flex: 0 0 58.333%;
    max-width: 58.333%;
  }
  #{$screenSize}-8 {
    flex: 66.666%;
    max-width: 66.666%;
  }
  #{$screenSize}-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  #{$screenSize}-10 {
    flex: 0 0 83.333%;
    max-width: 83.333%;
  }
  #{$screenSize}-11 {
    flex: 0 0 91.666%;
    max-width: 91.666%;
  }
  #{$screenSize}-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 1199px) {
  @include flexBox('.col-lg');
}

@media (max-width: 1199px) {
  @include flexBox('.col-lg');
}

@media (max-width: 992px) {
  @include flexBox('.col-md');
}

@media (max-width: 767px) {
  @include flexBox('.col-sm');
}

@media (max-width: 500px) {
  @include flexBox('.col-xs');
}
