.cwpf_attackmitigated_table {
  @apply text-gray-80;
  & > thead {
    @apply border-b border-solid border-gray-20;
    & > tr > th {
      @apply border-b border-solid border-gray-40;
      background-color: transparent;
      @apply normal-case;
    }
  }
  & > tbody {
    @apply border-t border-solid border-gray-20;
    & > tr > td {
      @apply border-b border-solid border-gray-10;
      background-color: transparent;
      @apply normal-case;
    }
  }
}

@media (max-width: 992px) {
  .cwpf_AttackMitiResp {
    flex-direction: column;
    .cwpf_AttackMitiColResp {
      margin-bottom: 20px;
    }
  }
}
